import * as React from "react";
import { Button, Form, Input, notification } from "antd";

/* eslint "no-console": "off" */

interface Data {
  recipientId: string;
}

export const InternalPage: React.FC = () => {
  const [busy, setBusy] = React.useState(false);
  const onFinish = (values: Data) => {
    const send = async (): Promise<void> => {
      const resp = await fetch("/push-service/api/test", {
        method: "post",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(values),
      });
      console.log("resp", resp);
      if (resp.status !== 200)
        throw Error(resp.statusText || `Status ${resp.status}`);
      const contentType = resp.headers.get("Content-Type");
      if (contentType === "application/json") {
        const jres = await resp.json();
        console.log("response", jres);
      }
    };
    setBusy(true);
    send()
      .then(() => notification.success({ message: "Success!" }))
      .catch((e) => notification.error(e.message))
      .finally(() => setBusy(false));
  };
  return (
    <div
      style={{
        padding: "1em",
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <h1>Отладочный режим</h1>
      <h2>Send test</h2>
      <Form
        layout="vertical"
        onFinish={onFinish}
        style={{ maxWidth: 300, padding: "1em 0" }}
      >
        <Form.Item name="recipientId" label="recipientId">
          <Input />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={busy}>
          Send
        </Button>
      </Form>
    </div>
  );
};
