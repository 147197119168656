import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  GlobalSearchFullMode,
  globalSearchStore as store,
} from "src/components/MainFrameLayout/GlobalSearch";
import { PageLayout } from "src/components/PageLayout/PageLayout";

export const GlobalSearchPage: React.FC = observer(() => {
  React.useEffect(() => {
    store.setFullMode(true);
    return () => {
      store.setFullMode(false);
      store.setActive(false);
    };
  }, []);
  return (
    <PageLayout>
      <GlobalSearchFullMode />
    </PageLayout>
  );
});
