// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("src/assets/fonts/Manrope/static/Manrope-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("src/assets/fonts/Manrope/static/Manrope-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Manrope\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n  font-display: swap;\n}\n@font-face {\n  font-family: \"Manrope\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n  font-weight: 500;\n  font-style: normal;\n  font-display: swap;\n}\n", "",{"version":3,"sources":["webpack://./src/fonts.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,4CAAA;EACA,+DAAA;EAEA,mBAAA;EACA,kBAAA;EACA,kBAAA;AAAF;AAGA;EACE,sBAAA;EACA,4CAAA;EACA,+DAAA;EAEA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAFF","sourcesContent":["@font-face {\n  font-family: \"Manrope\";\n  src: url(\"src/assets/fonts/Manrope/static/Manrope-Regular.ttf\");\n  src: url(\"src/assets/fonts/Manrope/static/Manrope-Regular.ttf\")\n    format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: \"Manrope\";\n  src: url(\"src/assets/fonts/Manrope/static/Manrope-Medium.ttf\");\n  src: url(\"src/assets/fonts/Manrope/static/Manrope-Medium.ttf\")\n    format(\"truetype\");\n  font-weight: 500;\n  font-style: normal;\n  font-display: swap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
