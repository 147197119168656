import * as React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { MainFrameLayout } from "src/components/MainFrameLayout";
import { homePageShortUrl, routeMap } from "src/navigation/routeMap";
import { makeUrl } from "src/navigation/makeUrl";

const NoMatch = () => {
  const location = useLocation();
  return (
    <h1>
      No match for <code>{location.pathname}</code> in Core.
    </h1>
  );
};

const navigateDef = <Navigate to={makeUrl(homePageShortUrl)} />;
export const MainFrame = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainFrameLayout />}>
        <Route index element={<Navigate to={makeUrl(homePageShortUrl)} />} />
        <Route index element={navigateDef} />
        {/**
         * на случай, если выбран язык, но нет раздела:
         * - srm
         * - admin
         */}
        <Route path="/:lang" element={navigateDef} />
        <Route path="/:lang">
          {routeMap.map(({ url, element }) => (
            <Route key={url} path={url} element={element} />
          ))}
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
