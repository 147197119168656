import { LoadingStatus } from "./LoadingStatus";

export interface RemoteData<T> {
  status: LoadingStatus;
  data?: T;
  error?: Error;
}

export const ifData = <T, Res>(
  box: RemoteData<T>,
  callback: (data: T) => Res
): Res | undefined => {
  const { status, data } = box;
  return status === "ready" && data !== undefined ? callback(data) : undefined;
};

export const ifError = <Res>(
  box: RemoteData<unknown>,
  callback: (error: Error) => Res
): Res | undefined => {
  const { status, error } = box;
  return status === "error" && !!error ? callback(error) : undefined;
};
