// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-overlays-ResetPasswordPage-ResetPasswordSuccessPage-module__resetPassSuccessModal--q727t {\n  max-width: 600px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/overlays/ResetPasswordPage/ResetPasswordSuccessPage.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ","sourcesContent":[".resetPassSuccessModal{\n    max-width: 600px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetPassSuccessModal": "src-components-overlays-ResetPasswordPage-ResetPasswordSuccessPage-module__resetPassSuccessModal--q727t"
};
export default ___CSS_LOADER_EXPORT___;
