// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-Forms-FormStd-FormStd-module__contentDevider--EOXDe {\n  width: 100%;\n  height: 1px;\n  margin: 24px 0px;\n  background: #efefef;\n}\n.src-components-Forms-FormStd-FormStd-module__heading--EtTlJ {\n  font-weight: 600;\n  font-size: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Forms/FormStd/FormStd.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAEA;EACE,gBAAA;EACA,eAAA;AAAF","sourcesContent":[".contentDevider {\n  width: 100%;\n  height: 1px;\n  margin: 24px 0px;\n  background: rgb(239, 239, 239);\n}\n\n.heading {\n  font-weight: 600;\n  font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentDevider": "src-components-Forms-FormStd-FormStd-module__contentDevider--EOXDe",
	"heading": "src-components-Forms-FormStd-FormStd-module__heading--EtTlJ"
};
export default ___CSS_LOADER_EXPORT___;
