// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-SpinOverlayCentred-styles-module__spinContainer--JKUev {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.src-components-SpinOverlayCentred-styles-module__overlay--qzHyf {\n  height: 100vh;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SpinOverlayCentred/styles.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACA;EACE,aAAA;AACF","sourcesContent":[".spinContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.overlay {\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinContainer": "src-components-SpinOverlayCentred-styles-module__spinContainer--JKUev",
	"overlay": "src-components-SpinOverlayCentred-styles-module__overlay--qzHyf"
};
export default ___CSS_LOADER_EXPORT___;
