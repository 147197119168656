/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const BellFilledSvg: React.FC<Partial<CustomIconComponentProps>> = (props) => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
    width="20"
    height="20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 0H8.5V1.13193C4.52098 1.8404 1.5 5.31741 1.5 9.5V16H0.5V17.5H19.5V16H18.5V9.5C18.5 5.31741 15.479 1.8404 11.5 1.13193V0Z"
    />
    <path d="M13 18.5V20H7V18.5H13Z" />
  </svg>
);

export const BellFilledStd: React.FC<Partial<CustomIconComponentProps>> = (
  props
) => <Icon component={BellFilledSvg} {...props} />;
