import { makeAutoObservable } from "mobx";
import i18 from "i18next";
import { RemoteData } from "src/types/RemoteData";
import { UserInfo } from "src/types/UserInfo";
import { AuthError } from "src/types/AuthError";
import { PermissionCode } from "src/types/PermissionCode";
import { CompanyType } from "src/types/CompanyInfo";
import { detectLang } from "src/common/detectLang";
import enRes from "src/lang/en";
import ruRes from "src/lang/ru";
import { notificationsStore } from "src/common/notificationsStore";
import { GlobalParams } from "./types/globalParams";
import globalParams from "../globalParams.json";

export type OverlayPage =
  | "login"
  | "passwordRestore"
  | "registrationCompanyBase"
  | "registrationCompanyDetailInfo"
  | "registrationUserInfo"
  | "registrationSuccessPage"
  | "resetPasswordSuccessPage";

const keyRefreshToken = "refreshToken";
const setRefreshToken = (value?: string) => {
  try {
    if (value) {
      localStorage.setItem(keyRefreshToken, value);
    } else {
      localStorage.removeItem(keyRefreshToken);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
const getRefreshToken = (): string =>
  localStorage.getItem(keyRefreshToken) || "";

let authWait: Promise<Response> | null = null;

const authTask = async (task: () => Promise<Response>): Promise<Response> => {
  let resp: Response;
  try {
    resp = await task();
  } catch (e) {
    if ("response" in e) {
      resp = e.response;
      if (resp.status !== 401) throw e;
    } else {
      throw e;
    }
  }
  if (resp.status === 401) {
    authWait =
      authWait ||
      fetch("/admin-service/api/auth/refresh", {
        method: "post",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ refresh: getRefreshToken() }),
      });
    const resp1 = await authWait;
    authWait = null;
    if (resp1.status !== 200) {
      const error = new AuthError("Authorization required");
      if (appStore.userInfo.status !== "error") {
        appStore.setUserInfo({ status: "error", error });
      }
      throw error;
    }
    resp = await task();
  }
  return resp;
};

const curLang = detectLang();

i18.init({
  lng: curLang,
  debug: window.location.href.startsWith("http://localhost"),
  resources: {
    en: enRes,
    ru: ruRes,
  },
});

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var srmCore: {
    user?: UserInfo;
    authTask: (task: () => Promise<Response>) => Promise<Response>;
    globalParams: GlobalParams;
    lang: string;
  };
}
window.srmCore = {
  authTask,
  globalParams,
  lang: curLang,
};

export class AppStore {
  constructor() {
    makeAutoObservable(this);
  }

  userInfo: RemoteData<UserInfo> = { status: "none" };

  setUserInfo(newUserInfo: RemoteData<UserInfo>) {
    this.userInfo = newUserInfo;
    if (window.srmCore) window.srmCore.user = newUserInfo.data;
    const { error } = this.userInfo;
    if (this.userInfo.status === "error" && error) {
      if (error.name === "AuthError") {
        this.setOverlayType("login");
      }
    }
  }

  // Проверка прав доступа пользователя на указанную операцию
  isAllowed(checkedPermission: PermissionCode): boolean {
    // сейчас запрос логина и пользователя возвращают разный формат
    const { data } = this.userInfo;
    if (data?.permissions) {
      // Это формат после запроса пользователя
      return !!data?.permissions?.find((code) => code === checkedPermission);
    }
    // Это после логина
    return !!data?.authorities?.find(
      ({ authority }) => authority === checkedPermission
    );
  }
  isCompanyType(type: CompanyType): boolean {
    return this.userInfo.data?.company?.type === type;
  }
  isRetailer(): boolean {
    return this.isCompanyType("RETAILER");
  }
  isSupplier(): boolean {
    return this.isCompanyType("SUPPLIER");
  }

  overlayType: OverlayPage | null = null;

  setOverlayType(newType: OverlayPage | null) {
    this.overlayType = newType;
  }

  curAppId: string = "";
  setCurAppId(appId: string) {
    this.curAppId = appId;
  }

  async init() {
    this.setUserInfo({ status: "loading" });
    try {
      const resp = await authTask(() =>
        fetch("/admin-service/api/auth/current")
      );
      if (resp.status !== 200) throw new Error(`Status ${resp.status}`);
      const rawData = await resp.json();
      this.setUserInfo({ status: "ready", data: rawData.user as UserInfo });
      notificationsStore.init();
    } catch (error) {
      this.setUserInfo({ status: "error", error });
    }
  }

  async login(username: string, password: string) {
    interface LoginResult {
      accessToken: string;
      refreshToken: string;
      user: UserInfo;
    }
    this.setUserInfo({ status: "loading" });
    try {
      const resp = await fetch("/admin-service/api/auth/login", {
        method: "post",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ username, password }),
      });
      if (resp.status !== 200) {
        const text = await resp.text();
        let message = "Ошибка";
        try {
          const json = JSON.parse(text);
          if ("message" in json) message = json.message;
          // eslint-disable-next-line no-empty
        } catch (e) {}
        throw new Error(message);
      }
      const rawData = (await resp.json()) as LoginResult;

      const { user, refreshToken } = rawData;
      user.companyId = user.company?.id;
      setRefreshToken(refreshToken);
      this.setUserInfo({ status: "ready", data: user });
      this.setOverlayType(null);
      notificationsStore.init();
    } catch (error) {
      this.setUserInfo({ status: "error", error });
    }
  }

  logout() {
    fetch("/admin-service/api/auth/logout"); // Не ждем результат, т.к. он не имеет смысла
    this.setUserInfo({ status: "none" });
    setRefreshToken();
    this.setOverlayType("login");
    notificationsStore.clear();
  }
}

export const appStore = new AppStore();
