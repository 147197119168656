import * as React from "react";

interface PropsLocalizationHref {
  href?: string;
  text: string;
  target?: string;
  download?: string;
}
export const LocalizationHref: React.FC<PropsLocalizationHref> = ({
  href,
  text,
  target,
  download,
}: PropsLocalizationHref) => {
  const supportBegining = text.replace(/<.*$/, "");
  const supportMiddle = text.replace(/^.*<(.*)>.*$/, "$1");
  const supportEnd = text.replace(/^.*>/, "");

  return (
    <>
      {supportBegining}
      <a href={href} target={target} download={download}>
        {supportMiddle}
      </a>
      {supportEnd}
    </>
  );
};

LocalizationHref.defaultProps = {
  href: undefined,
  target: undefined,
  download: undefined,
};
