import * as React from "react";
import styles from "./ServicesPage.module.less";
import { ServiceDescriptor } from "./ServiceDescriptor";
import { ServiceWidget } from "./ServiceWidget";
import headerIcon from "./headerIcon.svg";

const services: ServiceDescriptor[] = [
  {
    key: "plm",
    title: "Omnidata.PLM",
    subTitle: "Управление собственной торговой маркой с помощью ИИ",
    shortUrl: "/srm/price/list",
  },
  {
    key: "srm",
    title: "Omnidata.SRM",
    subTitle: "Управление собственной торговой маркой с помощью ИИ",
    shortUrl: "/srm",
  },
  {
    key: "secure",
    title: "Omnidata.Личный кабинет",
    subTitle: "Управление собственной торговой маркой с помощью ИИ",
    shortUrl: "/secure",
  },
];

export const ServicesPage: React.FC = () => (
  <div className={styles.page}>
    <h1>
      <img src={headerIcon} alt="" />
      <span>Сервисы</span>
    </h1>
    <article>
      {services.map((service) => (
        <ServiceWidget key={service.key} descriptor={service} />
      ))}
    </article>
  </div>
);
