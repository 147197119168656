import { makeAutoObservable } from "mobx";
import { dict2Options } from "src/common/dict2Options";
import { onError } from "src/common/onError";
import { ISelectOption } from "src/components/Forms/Select/types";
import {
  CountryCode,
  DictNode,
  defCC,
  isRu,
  isKz,
  isIndividual,
} from "src/types/CompanyInfo";
import { t } from "i18next";
import { makeUrlWithParams } from "src/common/makeUrlWithParams";
import { SignUpUserInfo } from "src/types/SignUpUserInfo";
import {
  CompanyDataModel,
  CompanyDataModelKZIndividual,
  CompanyDataModelRUEntity,
  CompanyDataModelRUIndividual,
  CompanyTypeVariatoins,
} from "./classes/CompanyDataModel";

type RegistrationDictKey = "taxSystem" | "legalForm";

/**
 * Из-за того, что DictKey различаются в зависимости от страны:
 * RU - legalForm
 * KZ - legalFormKz
 * ...
 * получать их по единому названию не получится, поэтому приходтся
 * делать сопостовление.
 */
const countryDictMapping: Record<
  CountryCode,
  Record<RegistrationDictKey, string>
> = {
  RU: { legalForm: "legalForm", taxSystem: "taxSystem" },
  KZ: { legalForm: "legalFormKz", taxSystem: "taxSystem" },
};

const getDictKeyByCountry = (
  country: CountryCode,
  dictKey: RegistrationDictKey
) => countryDictMapping[country][dictKey];

const getDictOptions = (
  dicts: RegistrationDictResponse[],
  key: RegistrationDictKey,
  country?: CountryCode
) => {
  const actualKey = getDictKeyByCountry(country || defCC, key);
  return dict2Options(
    dicts.find((d) => d.dictionaryKey === actualKey)?.dictionaries || []
  );
};

type RegistrationDictResponse = {
  dictionaryKey: string;
  dictionaries: DictNode[];
};

interface SearchFieldsType {
  inn?: string;
  ogrn?: string;
  ogrnip?: string;
}

interface CheckCompanyExist {
  field: string;
  message: string;
  data: Response;
}

const errorMappnig: Record<string, string> = {
  inn: t("Validation.Company with this S already exists", {
    field: "идентификатором",
  }),
  ogrn: t("Validation.Company with this S already exists", { field: "ОГРН" }),
  ogrnip: t("Validation.Company with this S already exists", {
    field: "ОГРНИП",
  }),
};

/**
 * У нас есть разные кейсы инициализации форм, например:
 * Для RU
 * - пользователь указал ИНН 12 символов, то правовые формы
 * должны быть отфильтрованы, чтобы осталась только опция с "ИП"
 * - если указал 10 символов, то "ИП" удаляется из правовых форм
 *
 * Для KZ
 * - если пользователь выбрал Казахстан, то оторажаем все полученные пф
 */
type FilterFunc = (
  list: ISelectOption<string>[],
  model: CompanyDataModel
) => ISelectOption<string>[];

const filterCountryMap: Record<
  CountryCode,
  Record<RegistrationDictKey | string, FilterFunc>
> = {
  RU: {
    legalForm: (
      legalForms: ISelectOption<string>[],
      model: CompanyDataModel
    ) => {
      const { legalType } = model;
      if (legalType === "ENTITY") {
        return legalForms.filter((item) => !isIndividual(item));
      }
      if (legalType === "INDIVIDUAL") {
        return legalForms.filter((item) => isIndividual(item));
      }
      return legalForms;
    },
  },
  KZ: {
    legalForm: (legalForms: ISelectOption<string>[]) => legalForms,
  },
};

const filterDictByCC = (conutry: CountryCode, dictKey: RegistrationDictKey) =>
  filterCountryMap[conutry]?.[dictKey];

export class SignUpStore {
  companyDataModel: CompanyDataModel = new CompanyDataModel();

  get companyInfo() {
    return this.companyDataModel.getInfo();
  }

  loading: boolean = false;

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  reset() {
    this.companyDataModel = new CompanyDataModel();
    this.userInfo = {
      password: "",
      userFirstName: "",
      userLastName: "",
      userMail: "",
    };
  }

  setCompanyInfo(companyInfo: CompanyTypeVariatoins) {
    this.companyDataModel?.setInfo(companyInfo);
  }

  setCompanyModel(country: CountryCode, inn: string) {
    if (isRu(country) && inn.length === 12) {
      this.companyDataModel = new CompanyDataModelRUIndividual();
    } else if (isRu(country) && inn.length === 10) {
      this.companyDataModel = new CompanyDataModelRUEntity();
    } else if (isKz(country)) {
      this.companyDataModel = new CompanyDataModelKZIndividual();
    } else {
      this.companyDataModel = new CompanyDataModel();
    }
  }

  // user
  userInfo: SignUpUserInfo = {
    password: "",
    userFirstName: "",
    userLastName: "",
    userMail: "",
  };

  setUserInfo(userInfo: SignUpUserInfo) {
    this.userInfo = userInfo;
  }

  constructor() {
    makeAutoObservable(this);
  }

  registrationDicts: RegistrationDictResponse[] = [];

  setRegistrationDicts(dicts: RegistrationDictResponse[]) {
    this.registrationDicts = dicts;
  }

  private async loadRegistrationDicts(country: CountryCode) {
    try {
      const response = await fetch(
        makeUrlWithParams(
          "/srm-service/api/dictionary/dictionary-for-registration",
          { country: country.toLocaleLowerCase() }
        )
      );

      this.setRegistrationDicts(
        (await response.json()) as RegistrationDictResponse[]
      );
    } catch (error) {
      onError(error);
    }
  }

  private filterLegalForms(legalForms: ISelectOption<string>[]) {
    return (
      filterDictByCC(this.companyInfo.country || defCC, "legalForm")?.(
        legalForms,
        this.companyDataModel
      ) || []
    );
  }

  get legalFormsOptions(): ISelectOption<string>[] {
    return this.filterLegalForms(
      getDictOptions(
        this.registrationDicts,
        "legalForm",
        this.companyDataModel.info.country
      )
    );
  }

  get taxSystemOptions() {
    return getDictOptions(
      this.registrationDicts,
      "taxSystem",
      this.companyDataModel.info.country
    );
  }

  async init(country: CountryCode) {
    try {
      this.setLoading(true);
      await this.loadRegistrationDicts(country);
      this.setLoading(false);
    } catch (error) {
      onError(error);
      this.setLoading(false);
    }
  }

  static async checkUserExist(userMail: string) {
    try {
      const response = await fetch(
        `/admin-service/api/users/${userMail}/exists`
      );
      return await response.json();
    } catch (error) {
      return false;
    }
  }

  static async checkCompanyExist(
    country: CountryCode,
    searchFields: SearchFieldsType
  ): Promise<{ field: string; message: string; data: Response }[]> {
    const responses: Promise<CheckCompanyExist>[] = [];
    Object.keys(searchFields).forEach((key) => {
      if (searchFields[key as keyof typeof searchFields]) {
        const result = fetch(
          `/admin-service/api/companies?country=${country}&${key}=${
            searchFields[key as keyof typeof searchFields]
          }`
        ).then((data) => ({ field: key, message: errorMappnig[key]!, data }));
        responses.push(result);
      }
    });

    const response = await Promise.all(responses);
    return response;
  }

  // GET /srm-service/api/dadata/companies?query=9724016019 -
  // бэк в качестве прокси. Ходит в ДАДАТА и возвращает результат оттуда.

  async getDatataInfo() {
    const response = await fetch(
      `/srm-service/api/dadata/companies?query=${this.companyDataModel.info.inn}`
    );

    if (!response.ok) {
      throw Object.create({
        message: t("Validation.Error loading information from dadata"),
      });
    }

    const result = await response.json();

    return result;
  }

  async singnUpCompany() {
    const {
      taxSystem,
      genDirFio,
      genDirPhone,
      comDirFio,
      comDirPhone,
      email,
      web,
      type,
      fundYear,
      ...modelData
      // eslint-disable-next-line no-unsafe-optional-chaining
    } = this.companyDataModel?.getInfo();

    const mrFields: Record<string, unknown> = {};
    // eslint-disable-next-line dot-notation
    mrFields.taxSystem = taxSystem;
    mrFields.email = email;
    mrFields.web = web;
    mrFields.fundYear = Number(fundYear?.format("YYYY"));
    mrFields.companyContactRegister = {
      genDirFio,
      genDirPhone,
      comDirFio,
      comDirPhone,
    };
    const getActualType = (currType: string) =>
      currType === "IMPORTER" ? "SUPPLIER" : currType;

    const companyData = {
      ...modelData,
      ...mrFields,
      type: getActualType(String(type)),
    };

    const response = await fetch(`/admin-service/api/companies`, {
      method: "post",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(companyData),
    });

    if (!response.ok) {
      throw Object.create({
        message: t("Validation.Error when registering a company"),
      });
    }

    const result = await response.json();
    return result;
  }

  async singnUpAdmin(companyId: string) {
    const adminData = {
      company: {
        id: companyId,
        type: "RETAILER",
      },

      firstName: this.userInfo.userFirstName,
      secondName: this.userInfo.userLastName,
      password: this.userInfo.password,
      username: this.userInfo.userMail,
    };

    const response = await fetch(`/admin-service/api/users/admins`, {
      method: "post",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(adminData),
    });

    if (!response.ok) {
      throw Object.create({ message: t("Validation.Error creating user") });
    }

    const res = await response.json();

    return res;
  }
}

export const signUpStore = new SignUpStore();
