// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-Modal-Modal-module__modal--cKVHP {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 32px;\n  background-color: #f7f7f7;\n  border-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;AADF","sourcesContent":["@base-primary-blue: #307EF3;\n\n.modal{\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 32px;\n  background-color: rgb(247, 247, 247);\n  border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "src-components-Modal-Modal-module__modal--cKVHP"
};
export default ___CSS_LOADER_EXPORT___;
