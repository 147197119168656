import * as React from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "src/appStore";
import { Modal } from "src/components/Modal/Modal";
import { Button } from "antd";
import { t } from "i18next";
import { LocalizationHref } from "src/components/LocalizationHref/LocalizationHref";
import commonStyles from "../common.module.less";
import styles from "./ResetPasswordSuccessPage.module.less";

interface PropsResetPasswordSuccessPage {
  store: AppStore;
}

export const ResetPasswordSuccessPage: React.FC<PropsResetPasswordSuccessPage> =
  observer(({ store }: PropsResetPasswordSuccessPage) => (
    <div>
      <Modal
        className={`${commonStyles.successModal} ${styles.resetPassSuccessModal}`}
      >
        <div className={commonStyles.successPageHeading}>
          {t("Password changed successfully")}
        </div>

        <div className={commonStyles.successPageSubHeading}>
          {t("Instructions new password")}
        </div>
        <div className={commonStyles.contentDevider} />
        <div className={commonStyles.successPageButton}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              store.setOverlayType("login");
            }}
          >
            {t("SignIn")}
          </Button>
        </div>

        <div className={commonStyles.successPageSubHeading}>
          <LocalizationHref
            href="https://d4r.atlassian.net/servicedesk/customer/portal/3"
            text={t("Registration.Support")}
          />
        </div>
      </Modal>
    </div>
  ));
