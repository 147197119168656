import * as React from "react";
import { Outlet } from "react-router-dom";
import { AuthChecker } from "src/components/AuthChecker";
import { appStore } from "src/appStore";
import { UserMenu } from "src/components/UserMenu";
import { observer } from "mobx-react-lite";
import { LangSwitcher } from "src/components/LangSwitcher";
import { makeUrl } from "src/navigation/makeUrl";
import { homePageShortUrl } from "src/navigation/routeMap";
import { AppRef } from "src/components/AppRef";
import { classNames } from "src/common/classNames";
import { Logo } from "./Logo";
import { Bell } from "./Bell";
import { GlobalSearch } from "./GlobalSearch";
import styles from "./MainFrameLayout.module.less";

const notificationPageUrl = "/srm/notifications";

export const MainFrameLayout: React.FC = observer(() => {
  const [activeBell, setActiveBell] = React.useState(
    window.location.pathname.includes(notificationPageUrl)
  );
  /**
   * Такой кастыль пришлось внедрить из-за того, что компонент с уведомлениями
   * находится в другом приложении и нам нужно подсвечивать элемент в меню в зависимости от
   * того находимся ли мы на странице уведомлений или нет
   */
  const onNotifications = React.useCallback((e: Event) => {
    if ("data" in e) {
      const { data } = e as unknown as {
        data: { msgId?: string; data: string };
      };
      if (data && data.msgId === "CoreRef") {
        if (data.data === "notificationsInit") setActiveBell(true);
        if (data.data === "notificationsDestroy") setActiveBell(false);
      }
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener("message", onNotifications);
    return () => {
      window.removeEventListener("message", onNotifications);
    };
  }, []);

  return (
    <AuthChecker store={appStore}>
      <div className={styles.mainFrame}>
        <header className={styles.header}>
          <div className={styles.headerLeft}>
            <a
              type="link"
              className={styles.servicesLink}
              onClick={() => {
                appStore.setCurAppId("");
              }}
              href={makeUrl("services")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect x="2" y="2" width="9.25" height="9.25" fill="white" />
                <rect x="12.75" y="2" width="9.25" height="9.25" fill="white" />
                <rect x="2" y="12.75" width="9.25" height="9.25" fill="white" />
                <rect
                  x="12.75"
                  y="12.75"
                  width="9.25"
                  height="9.25"
                  fill="white"
                />
              </svg>
            </a>

            <AppRef to={makeUrl(homePageShortUrl)} className={styles.logoLink}>
              <Logo />
            </AppRef>
          </div>

          <div className={styles.headerSearch}>
            <GlobalSearch />
          </div>

          <div className={styles.headerNav}>
            <div
              className={classNames([
                styles.headerNavItem,
                [activeBell, styles.headerNavItemActive],
              ])}
            >
              <Bell active={activeBell} />
            </div>
            <div className={styles.headerNavItem}>
              <LangSwitcher />
            </div>
            <div className={styles.headerNavItem}>
              <UserMenu />
            </div>
          </div>
        </header>

        <main>
          <Outlet />
        </main>
      </div>
    </AuthChecker>
  );
});
