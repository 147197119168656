import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Input } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { makeUrl } from "src/navigation/makeUrl";
import { appStore } from "src/appStore";
import { globalSearchStore as store } from "./globalSearchStore";
import { SelectSearchType } from "./SelectSearchType";
import { SearchListView } from "./SearchListView";
import styles from "./GlobalSearch.module.less";

/* eslint jsx-a11y/click-events-have-key-events: "off", jsx-a11y/no-static-element-interactions: "off" */

export const GlobalSearch: React.FC = observer(() => {
  const onFocus = () => {
    store.setActive(true);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    store.updateText(e.currentTarget.value);
  };
  const onClickOut = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    store.setActive(false);
  };
  const kbdHandler = (e: KeyboardEvent) => {
    if (store.isActive && e.code === "Escape") {
      store.setActive(false);
    }
  };
  const navigate = useNavigate();
  const goToSpecialPage = () => {
    appStore.setCurAppId("");
    navigate(makeUrl("/search"));
  };
  React.useEffect(() => {
    window.addEventListener("keydown", kbdHandler);
    return () => {
      window.removeEventListener("keydown", kbdHandler);
    };
  }, []);
  const { isExpanded } = store;
  return (
    <>
      {isExpanded && <div className={styles.overlay} onClick={onClickOut} />}
      <div className={styles.box}>
        <Input
          value={store.text}
          placeholder="Найти"
          addonAfter={
            <Button
              type="primary"
              disabled={store.fullMode}
              icon={<SearchOutlined />}
              onClick={goToSpecialPage}
            />
          }
          allowClear
          suffix={store.loading ? <LoadingOutlined /> : null}
          onChange={onChange}
          onFocus={onFocus}
        />
        {isExpanded && (
          <div className={styles.popover}>
            <SelectSearchType />
            <SearchListView />
          </div>
        )}
      </div>
    </>
  );
});
