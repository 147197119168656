// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-Alert-Alert-module__alert--iqkM0 {\n  border-radius: 2px;\n  padding: 8px 15px;\n}\n.src-components-Alert-Alert-module__error--v70IT {\n  background-color: #fff2f0;\n  border: thin solid #ffccc7;\n}\n.src-components-Alert-Alert-module__warning--aT_Ob {\n  background-color: #fffbe6;\n  border: thin solid #ffe58f;\n}\n.src-components-Alert-Alert-module__info--vAFJ0 {\n  background-color: #e6f7ff;\n  border: thin solid #91d5ff;\n}\n.src-components-Alert-Alert-module__success--NTIOA {\n  background-color: #f6ffed;\n  border: thin solid #b7eb8f;\n}\n.src-components-Alert-Alert-module__message--uueAf {\n  color: #000000d9;\n  font-size: 16px;\n}\n.src-components-Alert-Alert-module__description--y4DD4 {\n  font-size: 14px;\n  line-height: 22px;\n  color: #000000d9;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Alert/Alert.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;AACJ;AAEA;EACI,yBAAA;EACA,0BAAA;AAAJ;AAEA;EACI,yBAAA;EACA,0BAAA;AAAJ;AAGA;EACI,yBAAA;EACA,0BAAA;AADJ;AAIA;EACI,yBAAA;EACA,0BAAA;AAFJ;AAKA;EACI,gBAAA;EACA,eAAA;AAHJ;AAKA;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;AAHJ","sourcesContent":[".alert {\n    border-radius: 2px;\n    padding: 8px 15px;\n}\n\n.error {\n    background-color: rgb(255, 242, 240);\n    border: thin solid rgb(255, 204, 199);\n}\n.warning {\n    background-color: rgb(255, 251, 230);\n    border: thin solid rgb(255, 229, 143);\n}\n\n.info {\n    background-color: rgb(230, 247, 255);\n    border: thin solid rgb(145, 213, 255);\n}\n\n.success {\n    background-color: rgb(246, 255, 237);\n    border: thin solid rgb(183, 235, 143);\n}\n\n.message {\n    color: #000000d9;\n    font-size: 16px;\n}\n.description {\n    font-size: 14px;\n    line-height: 22px;\n    color: #000000d9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "src-components-Alert-Alert-module__alert--iqkM0",
	"error": "src-components-Alert-Alert-module__error--v70IT",
	"warning": "src-components-Alert-Alert-module__warning--aT_Ob",
	"info": "src-components-Alert-Alert-module__info--vAFJ0",
	"success": "src-components-Alert-Alert-module__success--NTIOA",
	"message": "src-components-Alert-Alert-module__message--uueAf",
	"description": "src-components-Alert-Alert-module__description--y4DD4"
};
export default ___CSS_LOADER_EXPORT___;
