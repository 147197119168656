/* eslint-disable react/jsx-props-no-spreading */
import { Button, Form, FormInstance, Row } from "antd";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import React from "react";
import style from "./FormStd.module.less";

export interface FormItemDef {
  render: (ctx?: FormInstance) => React.ReactNode;
}

type PropsFormStd<TFormData> = Omit<
  React.ComponentProps<typeof Form<TFormData>>,
  "onFinish" | "layout" | "scrollToFirstError"
> & {
  formItems: FormItemDef[];
  submitText?: string;
  submitIcon?: React.ReactNode;
  cancelText?: string;
  cancelIcon?: React.ReactNode;
  heading?: string;
  submit: (values: TFormData) => void;
  cancel?: () => void;
};

export const FormStd = observer(
  <TFormData,>({
    formItems,
    submitText,
    cancelText,
    submitIcon,
    cancelIcon,
    heading,
    submit,
    cancel,
    ...props
  }: PropsFormStd<TFormData>) => (
    <Form
      size="large"
      layout="vertical"
      scrollToFirstError
      {...props}
      onFinish={(values: TFormData) => submit(values)}
    >
      {heading && (
        <>
          <div className={style.heading}>{heading}</div>
          <div className={style.contentDevider} />
        </>
      )}

      {formItems.map((item) => item.render())}

      <div className={style.contentDevider} />

      <Row justify="space-between">
        <Button htmlType="submit" type="primary">
          {submitText || t("Continue")}
          {submitIcon}
        </Button>
        <Button onClick={() => cancel?.()} icon={cancelIcon}>
          {cancelText || t("Back")}
        </Button>
      </Row>
    </Form>
  )
);
