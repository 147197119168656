// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-Forms-FormStd-CaptchaFromItem-CaptchaFromItem-module__container--Gqm_6 {\n  display: grid;\n  grid-column-gap: 15px;\n  grid-template-columns: 1fr 1fr;\n}\n.src-components-Forms-FormStd-CaptchaFromItem-CaptchaFromItem-module__label--sGjDj {\n  height: 40px;\n  color: #858585;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Forms/FormStd/CaptchaFromItem/CaptchaFromItem.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,8BAAA;AACF;AAEA;EACE,YAAA;EACA,cAAA;AAAF","sourcesContent":[".container {\n  display: grid;\n  grid-column-gap: 15px;\n  grid-template-columns: 1fr 1fr;\n}\n\n.label {\n  height: 40px;\n  color: #858585;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-components-Forms-FormStd-CaptchaFromItem-CaptchaFromItem-module__container--Gqm_6",
	"label": "src-components-Forms-FormStd-CaptchaFromItem-CaptchaFromItem-module__label--sGjDj"
};
export default ___CSS_LOADER_EXPORT___;
