import * as React from "react";
import styles from "./Alert.module.less";

export type AlertType = "success" | "info" | "warning" | "error";

interface PropsAlert {
  message: React.ReactNode;
  description?: React.ReactNode;
  type: AlertType;
}

export const Alert: React.FC<PropsAlert> = ({
  type,
  message,
  description,
}: PropsAlert) => (
  <div className={`${styles.alert} ${styles[type]}`}>
    <div className={styles.message}>{message}</div>
    {!!description && <div className={styles.description}>{description}</div>}
  </div>
);

Alert.defaultProps = {
  description: undefined,
};
