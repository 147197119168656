/* eslint-disable no-unused-expressions */
import { Rule } from "antd/es/form";
import { t } from "i18next";
import { digitsCount } from "src/components/Forms/antValidators";
import { CountryCode } from "src/types/CompanyInfo";

export interface ICompanyIDMeta {
  label: string;
  placeholder: string;
  rules: Rule[];
}

// В зависимости от кода страны разные опции у инпута 'inn'
export const companyIDMetaMapping: Record<CountryCode, ICompanyIDMeta> = {
  RU: {
    rules: [
      {
        validator: (_, value) => {
          if (value.length !== 10 && value.length !== 12) {
            return Promise.reject(
              new Error(
                t("Validation.The INN must contain 10 or 12 characters") || ""
              )
            );
          }
          return Promise.resolve();
        },
      },
    ],

    label: t("INN"),
    placeholder: t("Placeholder.Enter the INN"),
  },
  KZ: {
    rules: [digitsCount(12, "BIN")],
    label: t("BIN"),
    placeholder: t("Placeholder.Enter the BIN"),
  },
};
