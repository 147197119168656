import * as React from "react";
import { classNames } from "src/common/classNames";
import { onAppRef } from "src/components/AppRef";
import { SearchListItem } from "./GlobalSearch.types";
import styles from "./GlobalSearch.module.less";

interface PropsSearchListItemView {
  item: SearchListItem;
  onClick(item: SearchListItem): void;
}

export const SearchListItemView: React.FC<PropsSearchListItemView> = ({
  item,
  onClick,
}: PropsSearchListItemView) => {
  const { text, prod, req, href } = item;
  const clsIcon = classNames([
    styles.icon,
    [!!prod, styles.product],
    [!!req, styles.request],
  ]);
  const clsHref = classNames([styles.name]);
  return (
    <div className={styles.item}>
      {href ? (
        <button
          type="button"
          className={clsHref}
          onClick={() => {
            onClick(item);
            onAppRef(href);
          }}
        >
          {text}
        </button>
      ) : (
        <span className={styles.name}>{text}</span>
      )}
      <div className={clsIcon} />
    </div>
  );
};
