import { Rule } from "antd/es/form";
import { t } from "i18next";

export const phoneValidator = (): Rule => ({
  pattern: /^(\+7)?[0-9]{3}[0-9]{3}[0-9]{4,6}$/,
  message: "Неверный формат телефонного номера",
});

export const emailValidator = () => ({
  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  message: t("Validation.Invalid email format") || "",
});

export const required = (): Rule => ({
  required: true,
  message: t("Validation.The field is mandatory") || "",
});
export const minLength = (length: number): Rule => ({
  min: length,
  message:
    t(`Validation.The captcha must contain at least ${length} characters`) ||
    "",
});
export const digitsCount = (count: number, field: string): Rule => ({
  len: count,
  message:
    t("Validation.There must be N digits in the S", {
      num: count,
      field: t(field),
    }) || "",
});

export const onlyNumbers = (): Rule => ({
  pattern: /^\d*$/,
  message: t("Validation.Only numbers") || "",
});
