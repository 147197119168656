export default {
  translation: {
    "Actual address": "Actual address",
    Administration: "Administration",
    Addresses: "Addresses",
    Back: "Back",
    BIN: "BIN",
    Captcha: "Captcha",
    "Commercial Director (Full name)": "Commercial Director (Full name)",
    "Company name": "Company name",
    "Company website": "Company website",
    "Congratulations S": "Congratulations! You are registered in {{name}}",
    Continue: "Continue",
    Country: "Country",
    Email: "Email",
    "Full name of the company": "Full name of the company",
    "General Director (Full name)": "General Director (Full name)",
    INN: "INN",
    Importer: "Importer",
    "Instructions new password":
      "Instructions with a new password have been sent to the specified email address.",
    Kazakhstan: "Kazakhstan",
    KPP: "KPP",
    "Legal address": "Legal address",
    "Legal form": "Legal form",
    Logout: "Logout",
    Manufacturer: "Manufacturer",
    "My profile": "My profile",
    "N more items": "{{count}} more items",
    "N more items_one": "{{count}} more item",
    Name: "Name",
    OGRN: "OGRN",
    OGRNIP: "OGRNIP",
    Password: "Password",
    "Password changed successfully": "Password changed successfully",
    "Password recovery": "Password recovery",
    Phone: "Phone",
    "Phone number of the General Director":
      "Phone number of the General Director",
    "Phone number of the commercial director":
      "Phone number of the commercial director",
    "Postal address": "Postal address",
    Register: "Register",
    "Registration in S": "Registration in {{name}}",
    "Repeat the password": "Repeat the password",
    "Restore password": "Restore password",
    "Request password": "Request password",
    "Russian Federation": "Russian Federation",
    SignIn: "Sign In",
    "Short name": "Short name",
    "Select a country": "Select a country",
    "Server error": "Server error",
    Supplier: "Supplier",
    Surname: "Surname",
    "Taxation system": "Taxation system",
    "Trading network": "Trading network",
    "Type of company": "Type of company",
    "Update the cod                e": "Update the code",
    User: "User",
    UserAgreementMessage:
      "I am familiar with the <user agreement>, privacy policy and agree to the processing of personal data",
    "User Information": "User Information",
    "Year of the company's foundation": "Year of the company's foundation",
    Background: {
      CommOffer: "Coordination of commercial offers",
      Description:
        "Automation of the processes of interaction between manufacturers, distributors and suppliers with retail chains",
      Header: "Process Automation",
      Promo: "Promo calendar",
      Revaluation: "Applications for revaluation",
    },
    GlobalSearchTypes: {
      ALL: "All",
      PRODUCTS: "Products",
      REQUESTS: "Requests",
    },
    GlobalSearchRequest: {
      agreement: "Application for contractual and commercial terms #{{id}}",
      commercial_proposal: "Application for a commercial offer #{{id}}",
      delivery_schedule: "Goods delivery schedule #{{id}}",
      new_product: "Application for input and updating of goods #{{id}}",
      promo: "Application for promo #{{id}}",
      other: "Application #{{id}}",
    },
    Placeholder: {
      "Full name": "Full name",
      "Enter a captcha": "Enter a captcha",
      "Enter email": "Enter email",
      "Enter a name": "Enter a name",
      "Enter a new password": "Enter a new password",
      "Enter your email address": "Enter your email address",
      "Enter your last name": "Enter your last name",
      "Enter the BIN": "Enter the BIN",
      "Enter the INN": "Enter the INN",
      "Select the type of company": "Select the type of company",
    },
    Registration: {
      clickLogin:
        "To enter the service, click the “Login” button and enter your account information",
      companyCode: "Company code:",
      createdAccontAdmin:
        "We have created a personal account for the company administrator",
      Password: "Password:",
      SpecifiedRegistration: "Specified at registration",
      Support:
        "Any questions left? You can contact technical support through the <User Support Portal>",
      Username: "Username:",
    },
    Validation: {
      "Company with this S already exists":
        "Company with this {{field}} already exists",
      "Error creating user": "Error creating user",
      "Error loading information from dadata":
        "Error loading information from dadata",
      "Error when registering a company": "Error when registering a company",
      "Incorrect code from the picture": "Incorrect code from the picture",
      "Invalid email format": "Invalid email format",
      "It is necessary to accept the agreement":
        "It is necessary to accept the agreement",
      "Only numbers": "Only numbers",
      "The captcha must contain at least 4 characters":
        "The captcha must contain at least 4 characters",
      "The field is mandatory": "The field is mandatory",
      "The INN must contain 10 or 12 characters":
        "The INN must contain 10 or 12 characters",
      "The password must contain at least 4 characters":
        "The password must contain at least 4 characters",
      "The user with this email is already registered":
        "The user with this email is already registered",
      "There must be N digits in the S":
        "There must be {{num}} digits in the {{field}}",
      "Passwords don't match": "Passwords don't match",
      "User is not found": "User is not found",
    },
  },
};
