import * as React from "react";
import styles from "./PageLayout.module.less";

interface PropsPageLayout {
  children: React.ReactNode;
}

export const PageLayout: React.FC<PropsPageLayout> = (
  props: PropsPageLayout
) => {
  const { children } = props;
  return <div className={styles.total}>{children}</div>;
};
