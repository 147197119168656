// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-overlays-RegistrationPages-RegistrationPages-module__registrationForm--RmhoZ {\n  max-width: 540px;\n  width: 100%;\n  position: static;\n  transform: none;\n  margin: auto;\n}\n.src-components-overlays-RegistrationPages-RegistrationPages-module__registrationFormLayout--LJxmA {\n  padding: 60px 0;\n}\n.src-components-overlays-RegistrationPages-RegistrationPages-module__heading--KHsgh {\n  font-weight: 600;\n  font-size: 24px;\n}\n.src-components-overlays-RegistrationPages-RegistrationPages-module__headingSm--CxGgU {\n  text-align: left;\n  font-weight: 600;\n  font-size: 18px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/overlays/RegistrationPages/RegistrationPages.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AACJ;AAEA;EACG,eAAA;AAAH;AAGA;EACI,gBAAA;EACA,eAAA;AADJ;AAIA;EACI,gBAAA;EACA,gBAAA;EACA,eAAA;AAFJ","sourcesContent":[".registrationForm{\n    max-width: 540px;\n    width: 100%;\n    position: static;\n    transform: none;\n    margin: auto;\n}\n\n.registrationFormLayout{\n   padding: 60px 0;\n}\n\n.heading {\n    font-weight: 600;\n    font-size: 24px;\n}\n\n.headingSm {\n    text-align: left;\n    font-weight: 600;\n    font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registrationForm": "src-components-overlays-RegistrationPages-RegistrationPages-module__registrationForm--RmhoZ",
	"registrationFormLayout": "src-components-overlays-RegistrationPages-RegistrationPages-module__registrationFormLayout--LJxmA",
	"heading": "src-components-overlays-RegistrationPages-RegistrationPages-module__heading--KHsgh",
	"headingSm": "src-components-overlays-RegistrationPages-RegistrationPages-module__headingSm--CxGgU"
};
export default ___CSS_LOADER_EXPORT___;
