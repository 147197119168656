import React from "react";
import { FormItemDef } from "../FormStd";

type PropsFormBlock = {
  heading: string;
  formItems: FormItemDef[];
};

export const FormBlock: React.FC<PropsFormBlock> = ({
  heading,
  formItems,
}: PropsFormBlock) => (
  <>
    {heading && <h3>{heading}</h3>} {formItems.map((item) => item.render())}
  </>
);

export const formBlock = (
  key: string,
  heading: string,
  formItems: FormItemDef[]
): FormItemDef => ({
  render: () => <FormBlock key={key} heading={heading} formItems={formItems} />,
});
