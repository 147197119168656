import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { SearchListItemView } from "./SearchListItemView";
import { globalSearchStore as store } from "./globalSearchStore";
import styles from "./GlobalSearch.module.less";

export const SearchListView: React.FC = observer(() => {
  const { visibleResults, isListVisible } = store;
  return (
    <>
      {" "}
      {isListVisible && (
        <div className={styles.listBox}>
          {visibleResults.map((item) => (
            <SearchListItemView
              key={item.key}
              item={item}
              onClick={() => {
                store.setActive(false);
              }}
            />
          ))}
          {store.nextVisibleIncrement > 0 && (
            <div className={styles.nextButtonBox}>
              <button
                type="button"
                className={styles.nextButton}
                onClick={() => store.nextPage()}
              >
                {t("N more items", { count: store.nextVisibleIncrement })}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
});
