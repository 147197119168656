import * as React from "react";
import { observer } from "mobx-react-lite";
import { SelectSearchType } from "./SelectSearchType";
import { SearchListView } from "./SearchListView";
import styles from "./GlobalSearch.module.less";

export const GlobalSearchFullMode: React.FC = observer(() => (
  <div className={styles.fullBox}>
    <SelectSearchType />
    <SearchListView />
  </div>
));
