import * as React from "react";
import { observer } from "mobx-react-lite";
import { AppStore, OverlayPage } from "src/appStore";
import { ifData, ifError } from "src/types/RemoteData";
import { LoginPage } from "../overlays/LoginPage";
import { RegistrationBasePage } from "../overlays/RegistrationPages";
import { RegistrationCompanyDetailInfoPage } from "../overlays/RegistrationPages/RegistrationCompanyDetailInfoPage";
import { RegistrationUserInfoPage } from "../overlays/RegistrationPages/RegistrationUserInfoPage";
import { RegistrationSuccessPage } from "../overlays/RegistrationPages/RegistrationSuccessPage";
import { ResetPasswordPage } from "../overlays/ResetPasswordPage/ResetPasswordPage";
import { ResetPasswordSuccessPage } from "../overlays/ResetPasswordPage/ResetPasswordSuccessPage";
import { SpinOverlayCentred } from "../SpinOverlayCentred/SpinOverlayCentred";

interface PropsAuthChecker {
  store: AppStore;
  children?: React.ReactNode;
}

// Если изменится состав типа OverlayPage, то компилятор потребует дополнить данный словарь.
const overlaysMap = (
  store: AppStore
): Record<OverlayPage, React.ReactElement> => ({
  login: <LoginPage store={store} />,
  passwordRestore: <ResetPasswordPage store={store} />,

  registrationCompanyBase: <RegistrationBasePage store={store} />,
  registrationCompanyDetailInfo: (
    <RegistrationCompanyDetailInfoPage store={store} />
  ),
  registrationUserInfo: <RegistrationUserInfoPage store={store} />,
  registrationSuccessPage: <RegistrationSuccessPage store={store} />,
  resetPasswordSuccessPage: <ResetPasswordSuccessPage store={store} />,
});

export const AuthChecker: React.FC<PropsAuthChecker> = observer(
  ({ store, children }: PropsAuthChecker) => {
    const { overlayType } = store;
    if (overlayType) {
      return (
        overlaysMap(store)[overlayType] ?? (
          <div>
            Unknown overlay <b>{store.overlayType}</b>
          </div>
        )
      );
    }
    return (
      ifError(store.userInfo, (error) => (
        <div>
          <div>Ошибка при обращении к серверу: {error.message}</div>
          <button type="button" onClick={() => store.init()}>
            Повтор
          </button>
        </div>
      )) ??
      // eslint-disable-next-line react/jsx-no-useless-fragment
      ifData(store.userInfo, () => <>{children}</>) ?? (
        <>
          {store.userInfo.status === "loading" && (
            <SpinOverlayCentred overlay />
          )}
          {store.userInfo.status === "none" && <LoginPage store={store} />}
        </>
      )
    );
  }
);

AuthChecker.defaultProps = {
  children: null,
};
