// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-pages-ServicesPage-ServicesPage-module__page--iFDr0 {\n  padding: 56px 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 60px;\n}\n.src-pages-ServicesPage-ServicesPage-module__page--iFDr0 h1 {\n  color: #414141;\n  font-size: 34px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 118%;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex-direction: row;\n  gap: 24px;\n}\n.src-pages-ServicesPage-ServicesPage-module__page--iFDr0 article {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ServicesPage/ServicesPage.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AALA;EAOQ,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAEA,SAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAAR;AAjBA;EAsBQ,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AAFR","sourcesContent":[".page {\n    padding: 56px 32px;\n    display: flex;\n    flex-direction: column;\n    gap: 60px;\n\n    h1 {\n        color: #414141;\n        font-size: 34px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: 118%;\n\n        margin: 0;\n        padding: 0;\n        display: flex;\n        flex-direction: row;\n        gap: 24px;\n    }\n\n    \n    article {\n        display: flex;\n        flex-direction: row;\n        flex-wrap: wrap;\n        gap: 32px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "src-pages-ServicesPage-ServicesPage-module__page--iFDr0"
};
export default ___CSS_LOADER_EXPORT___;
