// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-PageLayout-PageLayout-module__total--bgCuk {\n  padding: 14px 16px;\n  height: 100%;\n  box-sizing: border-box;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PageLayout/PageLayout.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,sBAAA;AACJ","sourcesContent":[".total {\n    padding: 14px 16px;\n    height: 100%;\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"total": "src-components-PageLayout-PageLayout-module__total--bgCuk"
};
export default ___CSS_LOADER_EXPORT___;
