import { makeAutoObservable } from "mobx";
import webstomp from "webstomp-client";
import { appStore } from "src/appStore";
import { notification } from "antd";
import { DebounceCounter, debounce } from "./debounce";

/* eslint "no-console": "off" */

const makeWSUrl = () =>
  `${window.location.origin.replace(/^http/, "ws")}/push-service/broadcast`;

export type ConnectionStatus = "none" | "connecting" | "ready";

export interface UnreadInfo {
  count: number;
  lastReadtime: string;
  type: string;
}

export const notificationsStore = makeAutoObservable({
  status: "none" as ConnectionStatus,
  setStatus(newStatus: ConnectionStatus) {
    this.status = newStatus;
  },

  unreadCountByType: [] as UnreadInfo[],
  setUnreadCountByType(list: UnreadInfo[]) {
    this.unreadCountByType = list;
  },
  get totalUnreadCount(): number {
    return this.unreadCountByType.reduce((sum, { count }) => sum + count, 0);
  },
  clearUnreadCount(type: string | undefined) {
    if (!type || type === "ALL") {
      this.setUnreadCountByType([]);
    } else {
      const pos = this.unreadCountByType.findIndex((rec) => rec.type === type);
      if (pos >= 0) this.unreadCountByType.splice(pos, 1);
    }
  },

  updateNotifications() {
    fetch(`/notification-center-service/api/notification?status=NEW`)
      .then((req) => req.json())
      .then((data) => {
        this.setUnreadCountByType(data.unreadCountByType);
        window.postMessage({ msgId: "updateNotifications" });
      });
  },

  init() {
    window.addEventListener("message", (e) => {
      const { data } = e;
      if (
        data &&
        typeof data === "object" &&
        data.msgId === "NotificationsOpened"
      ) {
        this.clearUnreadCount(data.type);
      }
    });

    this.updateNotifications();

    const connect = () => {
      const client = webstomp.client(makeWSUrl(), { debug: false });
      this.setStatus("connecting");
      client.connect(
        {},
        () => {
          this.setStatus("ready");
          const userId = appStore.userInfo.data?.id;
          client.subscribe(`/messages/${userId}`, (msg) => {
            console.log("Received", msg);
            debounce(msgCounter, 200, () => {
              // Чтобы сообщения не лезли слишком часто
              notification.info({ message: "Получено уведомление" });
            });
            this.updateNotifications();
          });
        },
        (e) => {
          this.setStatus("none");
          console.error("ERROR", e);
          if (e instanceof CloseEvent && e.code === 1006) {
            // Экспериментально вычисленная ситуация - разрыв соединения
            connect();
          }
        }
      );
    };
    connect();
  },
  clear() {
    this.setUnreadCountByType([]);
  },
});

let msgCounter: DebounceCounter = {};
