import * as React from "react";
import { onAppRef } from "src/components/AppRef";
import { makeUrl } from "src/navigation/makeUrl";
import { ServiceDescriptor } from "../ServiceDescriptor";
import styles from "./ServiceWidget.module.less";

interface PropsServiceWidget {
  descriptor: ServiceDescriptor;
}

export const ServiceWidget: React.FC<PropsServiceWidget> = ({
  descriptor,
}: PropsServiceWidget) => {
  const { title, subTitle, shortUrl } = descriptor;
  const href = makeUrl(shortUrl);
  return (
    <a className={styles.box} href={href}>
      <div className={styles.icon} />
      <h2>{title}</h2>
      <p>{subTitle}</p>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        onClick={(e) => {
          e.preventDefault();
          onAppRef(href);
        }}
      >
        <path d="M0 0H24V1.5H0V0Z" fill="#414141" />
        <path d="M22.5 24V0H24V24H22.5Z" fill="#414141" />
        <path
          d="M0.155888 22.7833L22.0762 0.862994L23.1369 1.92365L1.21655 23.844L0.155888 22.7833Z"
          fill="#414141"
        />
      </svg>
    </a>
  );
};
