import * as React from "react";
import { Button } from "antd";
import { appStore } from "src/appStore";
import { splitUrl } from "src/navigation/splitUrl";

/* eslint "no-console": "off" */

interface PropsAppRef {
  to: string | number | (() => string | number);
  className?: string;
  children: React.ReactNode;
}

export const onAppRef = (to: string | number) => {
  console.log("AppRef", "curApp:", appStore.curAppId, "to", to);
  if (typeof to === "number") {
    if (appStore.curAppId === "srm") {
      console.log("Internal num");
      window.postMessage({ msgId: "AppRef", to });
    } else {
      window.history.go(to);
    }
  } else {
    const { appId } = splitUrl(to);
    if (appId === appStore.curAppId) {
      console.log("Internal");
      window.postMessage({ msgId: "AppRef", to });
    } else {
      window.location.href = to;
    }
  }
};

/**
 * Ссылка на страницы внутри приложений.
 * Если использовать просто <Link>, то не будет работать внутренний роутер приложения.
 * Будет меняться урл в браузере, но страница бкдет оставаться старой.
 * А если использовать <a>, то будет полная перезагрузка приложения (как F5)
 *
 * Здесь выполняется отправка сообщения, которое ловят подчиненные приложения и используют navigate своего роутера.
 */
export const AppRef: React.FC<PropsAppRef> = ({
  to,
  children,
  className,
}: PropsAppRef) => (
  <Button
    type="text"
    className={className}
    onClick={() => onAppRef(typeof to === "function" ? to() : to)}
  >
    {children}
  </Button>
);

AppRef.defaultProps = {
  className: undefined,
};
