import * as React from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "src/appStore";
import { Modal } from "src/components/Modal/Modal";
import { Checkbox, Form, Input, notification, Spin } from "antd";
import { RuleObject } from "antd/es/form";
import { t } from "i18next";
import { LocalizationHref } from "src/components/LocalizationHref/LocalizationHref";
import { formBlock } from "src/components/Forms/FormStd/FormBlock/FormBlock";
import { formItemStd } from "src/components/Forms/FormStd/formItems/formItemStd";
import { required } from "src/components/Forms/antValidators";
import { FormItemDef, FormStd } from "src/components/Forms/FormStd/FormStd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { SignUpUserInfo } from "src/types/SignUpUserInfo";
import { formItemEmail } from "src/components/Forms/FormStd/formItems/formItemEmail";
import { CompanyDataModel } from "./classes/CompanyDataModel";
import { signUpStore, SignUpStore } from "./RegistrationPagesStore";
import commonStyles from "../common.module.less";
import styles from "./RegistrationPages.module.less";

interface PropsRegistrationBasePage {
  store: AppStore;
}

const agreementRule = (rule: RuleObject, value: unknown) =>
  value
    ? Promise.resolve()
    : Promise.reject(
        new Error(t("Validation.It is necessary to accept the agreement") || "")
      );
const passConfirmValidator = (userInput: string) => ({
  validator(_: unknown, value: string) {
    if (!value || userInput === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("Passwords don't match") || ""));
  },
});

/* eslint-disable react/jsx-props-no-spreading */
export const RegistrationUserInfoPage: React.FC<PropsRegistrationBasePage> =
  observer(({ store }: PropsRegistrationBasePage) => {
    const [form] = Form.useForm();

    const privacyPoliceMiddle = "pdf";

    const submitHandler = async (fromData: SignUpUserInfo) => {
      signUpStore.setUserInfo(fromData);
      signUpStore.setLoading(true);
      const isUserExist = await SignUpStore.checkUserExist(fromData.userMail);

      if (isUserExist) {
        form.setFields([
          {
            name: "userMail",
            errors: [
              t("Validation.The user with this email is already registered"),
            ],
          },
        ]);
        signUpStore.setLoading(false);
      } else {
        signUpStore
          .singnUpCompany()
          .then((response: { id: string }) => {
            signUpStore
              .singnUpAdmin(response.id)
              .then(() => {
                store.setOverlayType("registrationSuccessPage");
              })
              .catch(() => {
                notification.error({ message: t("Server error") });
                signUpStore.setLoading(false);
              });
          })
          .catch(() => {
            // eslint-disable-next-line no-console
            notification.error({ message: t("Server error") });
            signUpStore.setLoading(false);
          });
      }
    };
    const fromItems: FormItemDef[] = [
      formItemStd(
        "inn",
        CompanyDataModel.getCompanyIdMeta(signUpStore.companyInfo.country!)
          ?.label,
        Input,
        {
          disabled: true,
        },
        {
          rules: [required()],
        }
      ),
      formItemStd(
        "fullName",
        t("Company name"),
        Input,
        {
          disabled: true,
        },
        {
          rules: [required()],
        }
      ),
      formBlock("user", t("User"), [
        formItemStd(
          "userFirstName",
          t("Name"),
          Input,
          {
            placeholder: t("Placeholder.Enter a name"),
          },
          {
            rules: [required()],
          }
        ),
        formItemStd(
          "userLastName",
          t("Surname"),
          Input,
          {
            placeholder: t("Placeholder.Enter your last name"),
          },
          {
            rules: [required()],
          }
        ),
        formItemEmail(
          "userMail",
          t("Email"),
          {
            placeholder: t("Placeholder.Enter your email address") || "",
          },
          {
            rules: [required()],
          }
        ),
      ]),
      formBlock("password", t("Password"), [
        formItemStd(
          "password",
          t("Password"),
          Input.Password,
          {
            placeholder: t("Placeholder.Enter a new password"),
            autoComplete: "off",
          },
          {
            rules: [
              required(),
              {
                min: 4,
                message:
                  t(
                    "Validation.The password must contain at least 4 characters"
                  ) || "",
              },
            ],
            hasFeedback: true,
          }
        ),
        formItemStd(
          "passwordConfirm",
          t("Repeat the password"),
          Input.Password,
          {
            placeholder: t("Placeholder.Enter a new password"),
            autoComplete: "off",
          },
          {
            rules: [
              required(),
              ({ getFieldValue }) =>
                passConfirmValidator(getFieldValue("password")),
            ],
            hasFeedback: true,
            dependencies: ["password"],
          }
        ),
      ]),
      formItemStd(
        "agreement",
        "",
        Checkbox,
        {
          children: (
            <span>
              <LocalizationHref
                target="_blank"
                download={privacyPoliceMiddle}
                text={t("UserAgreementMessage")}
              />
            </span>
          ),
        },
        {
          rules: [
            {
              validator: agreementRule,
            },
          ],
          valuePropName: "checked",
        }
      ),
    ];

    return (
      <div className={styles.registrationFormLayout}>
        {signUpStore.loading && (
          <Spin className={commonStyles.spinnerWrapper} size="large" />
        )}

        <Modal className={styles.registrationForm}>
          <FormStd
            initialValues={signUpStore.companyDataModel.getInfo()}
            heading={t("User Information") || ""}
            name="userInfo"
            autoComplete="off"
            submit={submitHandler}
            cancel={() => {
              store.setOverlayType("registrationCompanyDetailInfo");
            }}
            formItems={fromItems}
            form={form}
            submitIcon={<ArrowRightOutlined />}
            cancelIcon={<ArrowLeftOutlined />}
            submitText={t("Register") || ""}
          />
        </Modal>
      </div>
    );
  });
