import { AutoComplete, Input } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import React from "react";
import { debounce, DebounceCounter } from "src/common/debounce";

export interface Suggestion {
  value: string;
}

export interface Suggestions {
  suggestions: Suggestion[];
}

interface PropAutoCompliteAsync {
  source: (value: string) => Promise<Suggestions>;
  value?: string;
  onChange?: (v: string) => void;
  delay?: number;
  size?: SizeType;
  placeholder?: string;
}

const debounceCounter: DebounceCounter = {};
const { Search } = Input;

export const AutoCompliteAsync: React.FC<PropAutoCompliteAsync> = ({
  source,
  value,
  delay,
  size,
  placeholder,
  onChange,
}: PropAutoCompliteAsync) => {
  const [options, setOptions] = React.useState<Suggestion[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const load = (data: string) => {
    if (!data) return;

    debounce(debounceCounter, delay!, () => {
      setLoading(true);
      source(data)
        .then(({ suggestions }: Suggestions) => {
          setOptions(suggestions);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <AutoComplete
      value={value}
      onChange={onChange}
      options={options}
      onSearch={load}
    >
      <Search loading={loading} size={size} placeholder={placeholder} />
    </AutoComplete>
  );
};
AutoCompliteAsync.defaultProps = {
  value: undefined,
  onChange: undefined,
  delay: 1000,
  size: "large",
  placeholder: "",
};
