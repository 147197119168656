import * as React from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "src/appStore";
import { Modal } from "src/components/Modal/Modal";
import { getGlobalParams } from "src/utils";
import { Button } from "antd";
import { t } from "i18next";
import { LocalizationHref } from "src/components/LocalizationHref/LocalizationHref";
import commonStyles from "../common.module.less";
import styles from "./RegistrationPages.module.less";
import { signUpStore } from "./RegistrationPagesStore";

interface PropsRegistrationSuccessPage {
  store: AppStore;
}

export const RegistrationSuccessPage: React.FC<PropsRegistrationSuccessPage> =
  observer(({ store }: PropsRegistrationSuccessPage) => (
    <div className={styles.registrationFormLayout}>
      <Modal className={commonStyles.successModal}>
        <div className={commonStyles.successPageHeading}>
          {t("Congratulations S", { name: getGlobalParams()?.title || "" })}
          <br />
          {t("Registration.createdAccontAdmin")} &quot;
          {signUpStore.companyDataModel.info?.name}&quot;.
        </div>

        <div className={commonStyles.successPageSubHeading}>
          {t("Registration.clickLogin")}
        </div>

        <div className={commonStyles.userInfoContainer}>
          <div className={commonStyles.userInfoRow}>
            <span className={commonStyles.userInfoLabel}>
              {t("Registration.Username")}
            </span>{" "}
            <span className={commonStyles.userInfoValue}>
              {signUpStore.userInfo.userMail}
            </span>
          </div>
          <div className={commonStyles.userInfoRow}>
            <span className={commonStyles.userInfoLabel}>
              {t("Registration.Password")}
            </span>{" "}
            <span className={commonStyles.userInfoValue}>
              {t("Registration.SpecifiedRegistration")}
            </span>
          </div>
          <div className={commonStyles.userInfoRow}>
            <span className={commonStyles.userInfoLabel}>
              {t("Registration.companyCode")}
            </span>{" "}
            <span className={commonStyles.userInfoValue}>
              {signUpStore.companyDataModel.info?.inn}
            </span>
          </div>
        </div>

        <div className={commonStyles.successPageButton}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              store.setOverlayType("login");
              signUpStore.reset();
            }}
          >
            {t("SignIn")}
          </Button>
        </div>

        <div className={commonStyles.successPageSubHeading}>
          <LocalizationHref
            href="https://d4r.atlassian.net/servicedesk/customer/portal/3"
            text={t("Registration.Support")}
          />
        </div>
      </Modal>
    </div>
  ));
