import * as React from "react";
import { MicroApp } from "src/components/MicroApp";
import { InternalPage } from "src/components/InternalPage";
import { GlobalSearchPage } from "src/pages/GlobalSearchPage";
import { ServicesPage } from "src/pages/ServicesPage";

interface RouteItem {
  url: string;
  element: React.ReactNode;
}

export const homePageShortUrl = "srm";

export const routeMap: RouteItem[] = [
  { url: "internal", element: <InternalPage /> },
  { url: "secure/*", element: <MicroApp name="secure" /> },
  { url: "srm/*", element: <MicroApp name="srm" /> },
  { url: "search", element: <GlobalSearchPage /> },
  { url: "services", element: <ServicesPage /> },
];
