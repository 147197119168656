/* eslint-disable dot-notation */
import * as React from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "src/appStore";
import { getGlobalParams } from "src/utils";
import { Logo } from "src/components/MainFrameLayout/Logo";
import { Button, Form, Input, Row, Select } from "antd";
import i18, { t } from "i18next";
import { LocalizationHref } from "src/components/LocalizationHref/LocalizationHref";
import commonStyles from "../common.module.less";
import styles from "./LoginPage.module.less";

interface PropsLoginPage {
  store: AppStore;
}

interface LangDef {
  value: string;
  label: string;
}

const makeRef = (href: string, lang: string): string => {
  const onLang = href.includes(`/${i18.language}`);
  return onLang
    ? href.replace(`/${i18.language}`, `/${lang}`)
    : `${href}${lang}`;
};

export const LoginPage: React.FC<PropsLoginPage> = observer(
  ({ store }: PropsLoginPage) => {
    const [touched, setTouched] = React.useState(false);
    const firstField = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
      if (firstField.current) firstField.current.focus();
      setTouched(false);
    }, []);

    const langDefs: LangDef[] = [
      { value: "ru", label: "RU" },
      { value: "en", label: "EN" },
    ];
    return (
      <div className={commonStyles.loginPage}>
        <div className={commonStyles.formContainer}>
          <div>
            <div className={commonStyles.langDiv}>
              <Select
                className={commonStyles.langSelect}
                defaultValue={i18.language}
                options={langDefs}
                onChange={(langOption) => {
                  window.location.href = makeRef(
                    window.location.href,
                    langOption
                  );
                }}
              />
            </div>
            <Form
              name="login"
              onFinish={({ login, password }) => {
                store.login(login, password);
                setTouched(true);
              }}
              autoComplete="off"
              layout="vertical"
              scrollToFirstError
              size="large"
            >
              <Form.Item>
                <h2>{getGlobalParams()?.title || ""}</h2>
              </Form.Item>
              <Form.Item
                label={t("Placeholder.Enter email")}
                name="login"
                rules={[
                  {
                    required: true,
                    message: t("Validation.The field is mandatory") || "",
                  },
                  {
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: t("Validation.Invalid email format") || "",
                  },
                ]}
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                label={t("Password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("Validation.The field is mandatory") || "",
                  },
                ]}
              >
                <Input.Password placeholder={t("Password") || ""} />
              </Form.Item>

              {touched && !!store.userInfo.error && (
                <Form.Item>
                  <div className={commonStyles.errMsg}>
                    {store.userInfo.error.message}
                  </div>
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  type="link"
                  size="small"
                  className={styles.resetPasswordLink}
                  onClick={() => {
                    store.setOverlayType("passwordRestore");
                  }}
                >
                  {t("Restore password")}
                </Button>
              </Form.Item>

              <Row justify="space-between" align="middle">
                <Form.Item>
                  <Button
                    disabled={store.userInfo.status === "loading"}
                    htmlType="button"
                    onClick={() =>
                      store.setOverlayType("registrationCompanyBase")
                    }
                  >
                    {t("Register")}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={store.userInfo.status === "loading"}
                    htmlType="submit"
                    type="primary"
                  >
                    {t("SignIn")}
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
          <div>
            <div className={commonStyles.loginCopyrightLabel}>
              <LocalizationHref
                href="https://d4r.atlassian.net/servicedesk/customer/portal/3"
                text={t("Registration.Support")}
              />
            </div>
            <div className={commonStyles.loginCopyrightLabel}>
              {getGlobalParams()?.copyright || ""}
            </div>
          </div>
        </div>
        <div className={commonStyles.loginBackground}>
          <div className={commonStyles.loginBackgroundInfo}>
            <Logo />
            <p className={commonStyles.loginBackgroundInfoLabel}>
              {t("Background.Header")}
            </p>
            <p className={commonStyles.loginBackgroundInfoLabelSmall}>
              {t("Background.Description")}
            </p>
            <ul className={commonStyles.loginBackgroundUl}>
              <li>{t("Background.CommOffer")}</li>
              <li>{t("Background.Revaluation")}</li>
              <li>{t("Background.Promo")}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
);
