import { z } from "zod";

export const zRequestType = z.enum([
  "agreement",
  "commercial_proposal",
  "delivery_schedule",
  "new_product",
  "promo",
] as const);
export type ZRequestType = z.infer<typeof zRequestType>;

export const zSearchProduct = z.object({
  id: z.number(),
  name: z.string(),
});

export type ZSearchProduct = z.infer<typeof zSearchProduct>;

export const zSearchRequest = z.object({
  id: z.string(),
  type: zRequestType,
});

export type ZSearchRequest = z.infer<typeof zSearchRequest>;

export const zSearchResult = z.object({
  supplierProducts: zSearchProduct.array(),
  requests: zSearchRequest.array(),
});

export type ZSearchResult = z.infer<typeof zSearchResult>;

export type SearchListItem = {
  key: string;
  text: string;
  href: string;
  prod?: ZSearchProduct;
  req?: ZSearchRequest;
};
