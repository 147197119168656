import { t } from "i18next";
import { CompanyType, CountryCode } from "./CompanyInfo";

export const companyCountries: Record<CountryCode, string> = {
  KZ: t("Kazakhstan"),
  RU: t("Russian Federation"),
};

type ExtednedCompanyType = CompanyType | string;
export const companyTypes: Record<ExtednedCompanyType, string> = {
  PRODUCER: t("Manufacturer"),
  RETAILER: t("Trading network"),
  SUPPLIER: t("Supplier"),
};
