/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
import * as React from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "src/appStore";
import { Modal } from "src/components/Modal/Modal";
import {
  CountryCode,
  DadataResponse,
  isRu,
  isKz,
  CompanyStatus,
} from "src/types/CompanyInfo";
import {
  AutoCompliteAsync,
  Suggestions,
} from "src/components/Forms/AutoCompliteAsync/AutoCompliteAsync";
import { DatePicker, Form, Input, Select, Spin, notification } from "antd";
import { FieldData } from "rc-field-form/es/interface";
import {
  digitsCount,
  onlyNumbers,
  phoneValidator,
  required,
} from "src/components/Forms/antValidators";
import moment from "moment";
import { disabledDateBefore } from "src/common/disabledDateBefore";
import { t } from "i18next";
import { FormItemDef, FormStd } from "src/components/Forms/FormStd/FormStd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { formItemStd } from "src/components/Forms/FormStd/formItems/formItemStd";
import { formBlock } from "src/components/Forms/FormStd/FormBlock/FormBlock";
import { ifs } from "src/common/ifs";
import { getGlobalParams } from "src/utils";
import { formItemEmail } from "src/components/Forms/FormStd/formItems/formItemEmail";
import { PhoneInput } from "src/components/PhoneInput";
import { SignUpStore, signUpStore } from "./RegistrationPagesStore";
import styles from "./RegistrationPages.module.less";
import commonStyles from "../common.module.less";
import {
  CompanyDataModel,
  CompanyTypeVariatoins,
} from "./classes/CompanyDataModel";

interface PropsRegistrationBasePage {
  store: AppStore;
}

/* eslint-disable react/jsx-props-no-spreading */
export const RegistrationCompanyDetailInfoPage: React.FC<PropsRegistrationBasePage> =
  observer(({ store }: PropsRegistrationBasePage) => {
    const [form] = Form.useForm();
    const isRuCountry = isRu(signUpStore.companyInfo.country);
    const isKzCountry = isKz(signUpStore.companyInfo.country);
    const innWatched = Form.useWatch("inn", form);
    const supportEmail = getGlobalParams()?.supportEmail;
    const companyStatusMessage = (
      status: CompanyStatus | undefined,
      inn: number
    ) => {
      if (!status) {
        return t("RegValidationINN.DEFAULT", {
          email: supportEmail,
        });
      }
      const messageValidation: Record<CompanyStatus | string, string> = {
        LIQUIDATING: t("RegValidationINN.LIQUIDATING", {
          inn,
          email: supportEmail,
        }),
        LIQUIDATED: t("RegValidationINN.LIQUIDATED", {
          inn,
          email: supportEmail,
        }),
        BANKRUPT: t("RegValidationINN.BANKRUPT", {
          inn,
          email: supportEmail,
        }),
        REORGANIZING: t("RegValidationINN.REORGANIZING", {
          inn,
          email: supportEmail,
        }),
      };
      return messageValidation[status];
    };

    React.useEffect(() => {
      // подгружаем из dadata только если компания в России
      if (isRuCountry && !signUpStore.loading) {
        signUpStore.setLoading(true);
        signUpStore
          .getDatataInfo()
          .then(({ suggestions }: DadataResponse) => {
            const data = suggestions[0]?.data;
            const status = data?.state?.status;
            if (!data || status !== "ACTIVE") {
              store.setOverlayType("registrationCompanyBase");
              notification.error({
                message: companyStatusMessage(status, innWatched),
              });
            } else {
              signUpStore.companyDataModel.initDadata(data!, {
                legalFormOptions: signUpStore.legalFormsOptions,
              });
            }
            form.resetFields();
            signUpStore.setLoading(false);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
            if (signUpStore.legalFormsOptions.length === 1) {
              signUpStore.companyDataModel.info.legalForm =
                signUpStore.legalFormsOptions[0]?.value!;
            }
            form.resetFields();
            signUpStore.setLoading(false);
          });
      }
    }, []);

    const validate = async (
      fileds: CompanyTypeVariatoins,
      country: CountryCode
    ): Promise<boolean> => {
      const responses = await SignUpStore.checkCompanyExist(country, fileds);
      const fieldsWithErrors: FieldData[] = [];
      responses.forEach((item) => {
        // Если компания нашлась
        if (item.data.status === 200) {
          fieldsWithErrors.push({
            name: item.field,
            errors: [item.message],
          });
        }
      });

      if (fieldsWithErrors.length > 0) {
        form.setFields(fieldsWithErrors);
      }

      return fieldsWithErrors.length === 0;
    };

    const submitHandler = (formData: CompanyTypeVariatoins) => {
      const { ogrn, ogrnip } = formData;
      signUpStore.setCompanyInfo(formData);
      signUpStore.setLoading(true);
      validate(
        { ogrn, ogrnip } as CompanyTypeVariatoins,
        signUpStore.companyInfo.country!
      ).then((valid) => {
        if (valid) {
          store.setOverlayType("registrationUserInfo");
        }
        signUpStore.setLoading(false);
      });
    };

    const loadAddresses = async (value: string): Promise<Suggestions> => {
      const res = await fetch(
        `/srm-service/api/dadata/addresses?query=${value}`
      );
      const data = await res.json();
      return data;
    };
    const fromItems: FormItemDef[] = [
      formItemStd(
        "fullName",
        t("Full name of the company"),
        Input,
        {
          placeholder: t("Full name of the company"),
        },
        {
          rules: [required()],
        }
      ),
      formItemStd(
        "name",
        t("Short name"),
        Input,
        {
          placeholder: t("Company name"),
        },
        {
          rules: [required()],
        }
      ),
      formItemStd(
        "inn",
        CompanyDataModel.getCompanyIdMeta(signUpStore.companyInfo.country!)
          ?.label,
        Input,
        {
          disabled: true,
        },
        {}
      ),
      formItemStd(
        "legalForm",
        t("Legal form"),
        Select,
        {
          placeholder: t("Legal form"),
          options: signUpStore.legalFormsOptions,
        },
        {
          rules: [required()],
        }
      ),
      ...ifs(
        [
          [
            !isKzCountry,
            [
              formItemStd(
                "taxSystem",
                t("Taxation system"),
                Select,
                {
                  placeholder: t("Taxation system"),
                  options: signUpStore.taxSystemOptions,
                },
                {
                  rules: [required()],
                }
              ),
            ],
          ],
        ],
        []
      ),
      formItemStd(
        "fundYear",
        t("Year of the company's foundation"),
        DatePicker,
        {
          placeholder: t("Year of the company's foundation"),
          picker: "year",
          disabledDate: disabledDateBefore(moment().endOf("year")),
        },
        {
          rules: [required()],
        }
      ),

      ...ifs(
        [
          /* если 10 символов */
          [
            signUpStore.companyDataModel.legalType === "ENTITY" && isRuCountry,
            [
              formItemStd(
                "ogrn",
                t("OGRN"),
                Input,
                {
                  placeholder: t("OGRN"),
                  maxLength: 13,
                },
                {
                  rules: [required(), digitsCount(13, "OGRN"), onlyNumbers()],
                }
              ),
              formItemStd(
                "kpp",
                t("KPP"),
                Input,
                {
                  placeholder: t("KPP"),
                  maxLength: 9,
                },
                {
                  rules: [required(), digitsCount(9, "KPP"), onlyNumbers()],
                }
              ),
            ],
          ],
          /* если 12 символов */
          [
            signUpStore.companyDataModel.legalType === "INDIVIDUAL" &&
              isRuCountry,
            [
              formItemStd(
                "ogrnip",
                t("OGRNIP"),
                Input,
                {
                  placeholder: t("OGRNIP"),
                  maxLength: 15,
                },
                {
                  rules: [required(), digitsCount(15, "OGRNIP"), onlyNumbers()],
                }
              ),
            ],
          ],
        ],
        []
      ),
      formBlock("addresses", t("Addresses"), [
        /* если 10 символов */
        ...ifs(
          [
            [
              signUpStore.companyDataModel.legalType === "ENTITY",
              [
                formItemStd(
                  "legalAddress",
                  t("Legal address"),
                  AutoCompliteAsync,
                  {
                    placeholder: t("Legal address"),
                    source: loadAddresses,
                  },
                  {
                    rules: [required()],
                  }
                ),
              ],
            ],
          ],
          []
        ),
        formItemStd(
          "factAddress",
          t("Actual address"),
          AutoCompliteAsync,
          {
            placeholder: t("Actual address"),
            source: loadAddresses,
          },
          {
            rules: [required()],
          }
        ),
        formItemStd(
          "postAddress",
          t("Postal address"),
          AutoCompliteAsync,
          {
            placeholder: t("Postal address"),
            source: loadAddresses,
          },
          {
            rules: [required()],
          }
        ),
      ]),
      formBlock("contacts", "Контакты", [
        formItemStd(
          "genDirFio",
          t("General Director (Full name)"),
          Input,
          {
            placeholder: t("Placeholder.Full name"),
          },
          {
            rules: [required()],
          }
        ),
        formItemStd(
          "genDirPhone",
          t("Phone number of the General Director"),
          PhoneInput,
          {
            placeholder: t("Phone"),
          },
          {
            rules: [required(), phoneValidator()],
          }
        ),
        formItemStd(
          "comDirFio",
          t("Commercial Director (Full name)"),
          Input,
          {
            placeholder: t("Placeholder.Full name"),
          },
          {}
        ),
        formItemStd(
          "comDirPhone",
          t("Phone number of the commercial director"),
          PhoneInput,
          {
            placeholder: t("Phone"),
          },
          { rules: [phoneValidator()] }
        ),
        formItemStd(
          "web",
          t("Company website"),
          Input,
          {
            placeholder: t("Company website"),
          },
          {}
        ),
        formItemEmail(
          "email",
          "E-mail компании",
          {
            placeholder: "E-mail",
          },
          {}
        ),
      ]),
    ];

    return (
      <div className={styles.registrationFormLayout}>
        {signUpStore.loading && (
          <Spin className={commonStyles.spinnerWrapper} size="large" />
        )}
        <Modal className={styles.registrationForm}>
          <FormStd
            initialValues={signUpStore.companyDataModel.getInfo()}
            heading="Основные сведения"
            name="detailInfo"
            autoComplete="off"
            submit={submitHandler}
            cancel={() => {
              store.setOverlayType("registrationCompanyBase");
            }}
            formItems={fromItems}
            form={form}
            submitIcon={<ArrowRightOutlined />}
            cancelIcon={<ArrowLeftOutlined />}
          />
        </Modal>
      </div>
    );
  });
