/* eslint-disable dot-notation */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { AppStore } from "src/appStore";
import { Captcha } from "@nabidam/react-captcha";
import { getGlobalParams } from "src/utils";
import { Logo } from "src/components/MainFrameLayout/Logo";
import { Button, Form, Input, notification, Row } from "antd";
import { t } from "i18next";
import { LocalizationHref } from "src/components/LocalizationHref/LocalizationHref";
import commonStyles from "../common.module.less";

type IFormDefaultValues = {
  userCaptcha: string;
  email: string;
};

interface PropsResetPasswordPage {
  store: AppStore;
}

export const ResetPasswordPage: React.FC<PropsResetPasswordPage> = ({
  store,
}: PropsResetPasswordPage) => {
  const [captcha, setCaptcha] = React.useState("");
  const captchaRef = React.useRef<{ initializeCaptcha: () => void }>(null);

  const validateCaptcha = (userCaptcha: string): boolean => {
    if (userCaptcha !== captcha) {
      notification.error({
        message: t("Validation.Incorrect code from the picture"),
      });
      captchaRef.current?.initializeCaptcha();
      return false;
    }
    return true;
  };

  const resetPassword = async (email: string) => {
    const response = await fetch(
      `/admin-service/api/users/${email}/random-password/refresh`,
      {
        method: "post",
      }
    );

    if (!response.ok) {
      throw Object.create({ message: t("Validation.User is not found") });
    }

    return response;
  };

  const submitHandler = ({ userCaptcha, email }: IFormDefaultValues) => {
    if (!validateCaptcha(userCaptcha)) {
      return;
    }

    resetPassword(email)
      .then(() => {
        store.setOverlayType("resetPasswordSuccessPage");
      })
      .catch(({ message }: { message: string }) => {
        notification.error({
          message,
        });
      });
  };

  return (
    <div className={commonStyles.loginPage}>
      <div className={commonStyles.formContainer}>
        <div>
          <Form
            name="resetPass"
            onFinish={submitHandler}
            autoComplete="off"
            layout="vertical"
            scrollToFirstError
            className={commonStyles.loginForm}
            size="large"
          >
            <Form.Item>
              <h2>{t("Password recovery")}</h2>
            </Form.Item>
            <Form.Item
              label={t("Email")}
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Validation.The field is mandatory") || "",
                },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t("Validation.Invalid email format") || "",
                },
              ]}
              normalize={(value) => value.trim()}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item label={t("Captcha")}>
              <Row
                align="top"
                justify="space-between"
                wrap={false}
                className={commonStyles.gap10}
              >
                <Captcha
                  setWord={setCaptcha}
                  ref={captchaRef}
                  inputEl={undefined}
                  reloadText={t("Update the code") || ""}
                  backgroundColor="#c9deff"
                  fontColor="#000"
                />
                <Form.Item
                  name="userCaptcha"
                  className={commonStyles.w100}
                  rules={[
                    {
                      required: true,
                      message: t("Validation.The field is mandatory") || "",
                    },
                    {
                      min: 4,
                      message:
                        t(
                          "Validation.The captcha must contain at least 4 characters"
                        ) || "",
                    },
                  ]}
                >
                  <Input placeholder={t("Placeholder.Enter a captcha") || ""} />
                </Form.Item>
              </Row>
            </Form.Item>

            <Row justify="space-between" align="middle">
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  {t("Request password")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={() => store.setOverlayType("login")}>
                  {t("Back")}
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
        <div>
          <div className={commonStyles.loginCopyrightLabel}>
            <LocalizationHref
              href="https://d4r.atlassian.net/servicedesk/customer/portal/3"
              text={t("Registration.Support")}
            />
          </div>
          <div className={commonStyles.loginCopyrightLabel}>
            {getGlobalParams()?.copyright || ""}
          </div>
        </div>
      </div>
      <div className={commonStyles.loginBackground}>
        <div className={commonStyles.loginBackgroundInfo}>
          <Logo />
          <p className={commonStyles.loginBackgroundInfoLabel}>
            {t("Background.Header")}
          </p>
          <p className={commonStyles.loginBackgroundInfoLabelSmall}>
            {t("Background.Description")}
          </p>
          <ul className={commonStyles.loginBackgroundUl}>
            <li>{t("Background.CommOffer")}</li>
            <li>{t("Background.Revaluation")}</li>
            <li>{t("Background.Promo")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
