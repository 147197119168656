import * as React from "react";
import styles from "./Modal.module.less";

interface PropsModal
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

export const Modal: React.FC<PropsModal> = (props: PropsModal) => {
  const { children, className } = props;

  return <div className={`${styles.modal} ${className}`}>{children}</div>;
};
