import { ThemeConfig } from "antd/es/config-provider/context";
import { AliasToken } from "antd/es/theme/internal";

const token: Partial<AliasToken> | undefined = {
  borderRadius: 2,
  fontFamily: "Manrope, sans-serif",
  colorPrimary: "#EC5528",
  colorTextPlaceholder: "#656869",
  colorTextBase: "#2b2b2b",
  fontWeightStrong: 500,
};

export const themeConfig: ThemeConfig | undefined = {
  token,
  components: {
    Input: { controlHeightLG: 44 },
    Select: { controlHeightLG: 44 },
    Button: { controlHeightLG: 44 },
  },
};
