/* eslint-disable react/jsx-props-no-spreading */
import { FormItemProps, Form } from "antd";
import React from "react";
import { FormItemDef } from "../FormStd";

export const formItemStd = <TProps extends object>(
  name: string,
  label: string,
  Comp: React.FC<TProps> | React.ComponentClass<TProps>,
  compProps: TProps,
  itemProps: FormItemProps
): FormItemDef => ({
  render: () => (
    <Form.Item key={name} {...itemProps} name={name} label={label}>
      <Comp {...compProps} />
    </Form.Item>
  ),
});
