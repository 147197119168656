import { ISelectOption } from "src/components/Forms/Select/types";

export type OrganizationType = "ENTITY" | "INDIVIDUAL";
export type CountryCode = "RU" | "KZ";
// Код страны по умолчанию
export const defCC: CountryCode = "RU";
export const isRu = (country?: CountryCode) => country === "RU";
export const isKz = (country?: CountryCode) => country === "KZ";

export const isIndividual = (legalForm: ISelectOption<string>) =>
  legalForm.label === "ИП";

export type CompanyType = "PRODUCER" | "RETAILER" | "SUPPLIER" | "IMPORTER";

export type CompanyStatus =
  | "ACTIVE"
  | "LIQUIDATING"
  | "LIQUIDATED"
  | "BANKRUPT"
  | "REORGANIZING";

export type DictNode = {
  id: string;
  key: string;
  value: string;
};

export interface CompanyInfo {
  id?: number;
  inn: string;
  ogrn?: string;
  ogrnip?: string;
  kpp?: string;
  type: CompanyType;
  name: string;
  factAddress?: string;
  legalAddress?: string;
  postAddress?: string;
  directorName?: string;
  directorPosition?: string;
  fullName: string;
  country: CountryCode;
  legalForm: DictNode;
}
export type DadataInfo = {
  // Названия компании
  name: {
    full: string;
    full_with_opf: string;
    short_with_opf: string;
  };
  // адреса
  address: {
    unrestricted_value: string;
  };
  // Правовая форма
  opf: {
    code: string;
    full: string;
    short: string;
    type: string;
  };
  management?: {
    name: string;
    post: string;
  };
  ogrn: string;
  kpp: string;
  emails?: { value: string }[];
  state: {
    status: CompanyStatus;
  };
};

export type DadataSuggestions = {
  data: DadataInfo;
};

export interface DadataResponse {
  suggestions: DadataSuggestions[];
}
