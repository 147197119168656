import * as React from "react";
import { Alert } from "src/components/Alert/Alert";
import { appStore } from "src/appStore";
import styles from "./MicroApp.module.less";
import { SpinOverlayCentred } from "../SpinOverlayCentred/SpinOverlayCentred";

interface PropsMicroApp {
  name: string;
}

export const MicroApp: React.FC<PropsMicroApp> = ({ name }: PropsMicroApp) => {
  appStore.setCurAppId(name);
  const url = `/${name}/static`;
  const rootId = `root`;
  const ref = React.useRef<HTMLDivElement>(null);
  const [errMsg, setErrMsg] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const setLoadingCb = React.useCallback((isLoading: boolean) => {
    setLoading(isLoading);
  }, []);
  React.useEffect(() => {
    const box = ref.current;
    if (!box) return;
    box.innerHTML = "";
    const loader = document.createElement("script");
    box.append(loader);
    loader.addEventListener("load", () => {
      // eslint-disable-next-line no-console
      console.log("Loaded", url);
      setLoadingCb(false);
    });

    loader.addEventListener("error", () => {
      setErrMsg(`Ошибка при загрузке приложения "${name}"`);
      setLoadingCb(false);
    });
    loader.setAttribute("src", `${url}/bundle.js`);
  }, [name]);
  return (
    <>
      {loading && <SpinOverlayCentred />}
      <div ref={ref} id={rootId} className={styles.microApp}>
        {!!errMsg && <Alert message={errMsg} type="error" />}
      </div>
    </>
  );
};
