import * as React from "react";
import i18 from "i18next";
import { debounce, DebounceCounter } from "src/common/debounce";
import { ArrowDownStd } from "src/icons/ArrowDownStd";
import { classNames } from "src/common/classNames";
import enIcon from "./en.png";
import ruIcon from "./ru.png";
import styles from "./LangSwitcher.module.less";

const makeRef = (href: string, lang: string): string =>
  href.replace(`/${i18.language}/`, `/${lang}/`);

interface LangDef {
  id: string;
  title: string;
  icon: string;
}

let observer: MutationObserver | undefined;
const delayCounter: DebounceCounter = {};

export const LangSwitcher: React.FC = () => {
  const [href, setHref] = React.useState("");
  React.useEffect(() => {
    // Необходимо отслеживать изменение текущего урла страницы, чтобы обновлять урлы ссылок.
    observer = new MutationObserver(() => {
      debounce(delayCounter, 50, () => {
        setHref(window.location.href);
      });
    });
    const root = document.getElementById("root");
    if (root) {
      observer.observe(root, {
        childList: true,
        subtree: true,
      });
    }
    return () => {
      if (observer) {
        observer.disconnect();
        observer = undefined;
      }
    };
  }, []);
  const [openLang, setOpenLang] = React.useState(false);
  const onUserClick = () => setOpenLang(!openLang);
  const onKey = React.useCallback((e: KeyboardEvent) => {
    if (e.code === "Escape") setOpenLang(false);
  }, []);
  const stopMouse = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);
  const onMouseDown = React.useCallback(() => {
    setOpenLang(false);
  }, []);
  React.useEffect(() => {
    window.addEventListener("keyup", onKey);
    window.addEventListener("mousedown", onMouseDown);
    return () => {
      window.removeEventListener("keyup", onKey);
      window.removeEventListener("mousedown", onMouseDown);
    };
  }, []);

  const langDefs: LangDef[] = [
    { id: "ru", title: "Русский", icon: ruIcon },
    { id: "en", title: "English", icon: enIcon },
  ];

  return (
    <div
      role="presentation"
      className={styles.langMenu}
      onClick={onUserClick}
      onMouseDown={stopMouse}
    >
      <span className={styles.langName}>{i18.language.toUpperCase()}</span>
      <ArrowDownStd
        className={classNames([styles.arrow, [openLang, styles.open]])}
      />
      {openLang && (
        <menu
          role="presentation"
          className={styles.menu}
          onClick={() => setOpenLang(false)}
          onMouseDown={stopMouse}
        >
          <ul>
            {langDefs.map(({ id }) => (
              <li key={id} className={i18.language === id ? styles.active : ""}>
                <a href={makeRef(href, id)}>{id.toUpperCase()}</a>
              </li>
            ))}
          </ul>
        </menu>
      )}
    </div>
  );
};
