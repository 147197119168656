/* eslint-disable dot-notation */
/* eslint-disable class-methods-use-this */
/* eslint-disable-next-line max-classes-per-file */
import { ISelectOption } from "src/components/Forms/Select/types";
import {
  CountryCode,
  DadataInfo,
  OrganizationType,
} from "src/types/CompanyInfo";
import { Moment } from "moment";
import { companyIDMetaMapping, ICompanyIDMeta } from "./json";

// назначение данных классов в том, чтобы удовлетворять широкой вариативности
// данных компании.

// у всех компаний есть:
//   - идентификатор (ИНН/БИН) (пока что бэк принимает "inn" для всех стран);
//   - полное наименование;
//   - краткое наименование;
//   - страна;
//   - тип
//   - правовая форма
//   - адрес
//   - почтовый адрес

export type CompanyBaseInfo = {
  inn: string;
  name: string;
  fullName: string;
  country?: CountryCode;
  type: string | null;
  legalForm: string | null;
  factAddress: string;
  postAddress: string;
  // для MR
  genDirFio?: string;
  genDirPhone?: string;
  comDirFio?: string;
  comDirPhone?: string;
  email?: string;
  web?: string;
  taxSystem?: string;
  fundYear?: Moment;
};

// у физ. лиц зарегистрированных в России есть:
//   - ОГРНИП;
export type CompanyRUIndividualInfo = {
  ogrnip: string;
} & CompanyBaseInfo;

// заглушка для Казахстана
export type CompanyKZIndividualInfo = {
  ogrnip: string;
} & CompanyBaseInfo;

// у юр. лиц зарегистрированных в России есть:
//   - юр. адрес;
//   - ОГРН;
//   - КПП;
export type CompanyRUEntityInfo = {
  legalAddress: string;
  ogrn: string;
  kpp: string;
} & CompanyBaseInfo;

export type CompanyTypeVariatoins = CompanyRUIndividualInfo &
  CompanyRUEntityInfo &
  CompanyKZIndividualInfo;

export class CompanyDataModel {
  info: CompanyBaseInfo = {
    inn: "",
    factAddress: "",
    country: undefined,
    fullName: "",
    legalForm: null,
    name: "",
    postAddress: "",
    type: null,
  };

  legalType: OrganizationType | "" = "";
  initedByDadata: boolean = false;

  setInfo(data: CompanyBaseInfo) {
    this.info = { ...this.info, ...data };
  }

  // у компаний разных стран разные данные для идентификатора
  // например, для России - ИНН, а для Казахстана БИН
  static getCompanyIdMeta(countryCode: CountryCode): ICompanyIDMeta {
    return companyIDMetaMapping[countryCode];
  }

  getInfo(): CompanyBaseInfo {
    return this.info;
  }

  initDadata(
    dadata: DadataInfo,
    options?: Record<"legalFormOptions", ISelectOption<string>[]>
  ) {
    const legalFroms = options?.legalFormOptions || [];
    this.info.factAddress = dadata.address.unrestricted_value;
    this.info.postAddress = dadata.address.unrestricted_value;
    this.info.fullName = dadata.name.full_with_opf;
    this.info.name = dadata.name.short_with_opf;
    this.info.genDirFio = dadata.management?.name;
    this.info.email = dadata.emails?.[0]?.value;
    const legalFrom = legalFroms.find(
      ({ label }) => label === dadata.opf.short
    );
    if (legalFrom) {
      this.info.legalForm = legalFrom.value;
    } else {
      this.info.legalForm = legalFroms[legalFroms.length - 1]?.value || null;
    }

    this.initedByDadata = true;
  }
}

export class CompanyDataModelRUIndividual extends CompanyDataModel {
  info: CompanyRUIndividualInfo;

  constructor() {
    super();

    this.info = {
      ...this.getInfo(),
      ogrnip: "",
    };

    this.legalType = "INDIVIDUAL";
  }

  setInfo(data: CompanyRUIndividualInfo) {
    this.info = { ...this.info, ...data };
  }

  getInfo(): CompanyRUIndividualInfo {
    return this.info;
  }

  initDadata(
    dadata: DadataInfo,
    options?: Record<string, ISelectOption<string>[]>
  ) {
    super.initDadata(dadata, options);
    this.info.ogrnip = dadata.ogrn;
    this.initedByDadata = true;
  }
}

export class CompanyDataModelRUEntity extends CompanyDataModel {
  info: CompanyRUEntityInfo;

  constructor() {
    super();

    this.info = {
      ...this.getInfo(),
      kpp: "",
      legalAddress: "",
      ogrn: "",
    };

    this.legalType = "ENTITY";
  }

  setInfo(data: CompanyRUEntityInfo) {
    this.info = { ...this.info, ...data };
  }

  getInfo(): CompanyRUEntityInfo {
    return this.info;
  }

  initDadata(
    dadata: DadataInfo,
    options?: Record<string, ISelectOption<string>[]>
  ) {
    super.initDadata(dadata, options);
    this.info.ogrn = dadata.ogrn;
    this.info.kpp = dadata.kpp;
    this.info.legalAddress = dadata.address.unrestricted_value;
    this.initedByDadata = true;
  }
}

// заглушка для казахстана
export class CompanyDataModelKZIndividual extends CompanyDataModel {
  info: CompanyKZIndividualInfo;

  constructor() {
    super();
    this.info = this.getInfo();
    this.legalType = "INDIVIDUAL";
  }

  setInfo(data: CompanyKZIndividualInfo) {
    this.info = { ...this.info, ...data };
  }

  getInfo(): CompanyKZIndividualInfo {
    return this.info;
  }
  initDadata(
    dadata: DadataInfo,
    options?: Record<string, ISelectOption<string>[]>
  ) {
    super.initDadata(dadata, options);
    this.initedByDadata = true;
  }
}
