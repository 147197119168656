import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { globalSearchStore as store } from "./globalSearchStore";
import styles from "./GlobalSearch.module.less";

export const SelectSearchType: React.FC = observer(() => (
  <div
    className={classNames([
      styles.typesList,
      [store.isListVisible, styles.divider],
    ])}
  >
    {store.typesList.map((key: string) => (
      <button
        type="button"
        key={key}
        className={classNames([
          styles.typeItem,
          [store.isActiveType(key), styles.active],
        ])}
        onClick={() => store.setActiveType(key)}
      >
        {t(`GlobalSearchTypes.${key}`)}
      </button>
    ))}
  </div>
));
