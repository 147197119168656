import { t } from "i18next";
import { makeUrl } from "src/navigation/makeUrl";
import {
  ZSearchResult,
  SearchListItem,
  ZSearchRequest,
} from "./GlobalSearch.types";

// Внимание! Урлы страниц определены в приложении srm. Если они там поменяются, то нужно менять и здесь.
// Расшарить их в данное время не представляется возможным.
// Динамически запрашивать не получится, т.к. не гарантируется что в момент отрисовки списка srm вообще загружен.
// А для статического связывания нужно заводить библиотеку, что пока делать не хотелось бы, т.к. это сильно усложнит процессы.
// Это было бы оправдвно, если бы продукт был стабильным. А сейчас он подвергается постоянным изменениям.
// Поэттому тяжеловесная конструкция с библиотекой создаст больше проблем, чем пользы.

export const makeSearchList = (src: ZSearchResult): SearchListItem[] => [
  ...src.supplierProducts.map((prod) => ({
    key: String(prod.id),
    text: prod.name,
    href: makeUrl(`/srm/supplier-product/${prod.id}`),
    prod,
  })),
  ...src.requests.map((req) => ({
    key: `${req.type}_${req.id}`,
    text: makeRequestTest(req),
    href: makeRequestUrl(req),
    req,
  })),
];

const makeRequestTest = ({ id, type }: ZSearchRequest): string =>
  t(`GlobalSearchRequest.${type}`, { id });

const makeRequestUrl = ({ id, type }: ZSearchRequest): string => {
  switch (type) {
    case "agreement":
      return makeUrl(`/srm/request/contract/${id}`);
    case "commercial_proposal":
      return makeUrl(`/srm/request/commercial-offer/${id}`);
    case "delivery_schedule":
      return makeUrl(`/srm/delivery-schedule/${id}`);
    case "new_product":
      return makeUrl(`/srm/request/new-product/${id}`);
    case "promo":
      return makeUrl(`/srm/request/promo/${id}`);
    default:
      return "";
  }
};
