import * as React from "react";
import { observer } from "mobx-react-lite";
import i18 from "i18next";
import { appStore } from "src/appStore";
import { ConfigProvider, notification } from "antd";
import ruAnt from "antd/lib/locale/ru_RU";
import enAnt from "antd/lib/locale/en_US";
import { MainFrame } from "./MainFrame";
import "./style.less";
import "./fonts.less";
import { themeConfig } from "./themeConfig";

interface GlobalMessage {
  cmd: string;
}

const onMessage = (event: { data?: unknown }) => {
  const { data } = event;
  if (typeof data === "object") {
    if ((data as GlobalMessage).cmd === "logout") {
      appStore.logout();
    }
  }
};

notification.config({ placement: "bottomRight" });

const antLocMap: Record<string, typeof ruAnt> = {
  ru: ruAnt,
  en: enAnt,
};
const antLocale = antLocMap[i18.language] || ruAnt;

const App: React.FC = observer(() => {
  React.useEffect(() => {
    appStore.init();
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);
  return (
    <React.StrictMode>
      <ConfigProvider locale={antLocale} theme={themeConfig}>
        <MainFrame />
      </ConfigProvider>
    </React.StrictMode>
  );
});

export default App;
