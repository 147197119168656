/* eslint-disable no-unused-expressions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable dot-notation */
/* eslint-disable import/no-extraneous-dependencies */
import * as React from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "src/appStore";
import { Modal } from "src/components/Modal/Modal";
import { CountryCode, defCC } from "src/types/CompanyInfo";
import { getGlobalParams } from "src/utils";
import { Form, Input, Select, Spin } from "antd";
import { FieldData } from "rc-field-form/es/interface";
import { t } from "i18next";
import { FormItemDef, FormStd } from "src/components/Forms/FormStd/FormStd";
import { formItemStd } from "src/components/Forms/FormStd/formItems/formItemStd";
import { captchaFormItem } from "src/components/Forms/FormStd/CaptchaFromItem/CaptchaFromItem";
import { companyCountries, companyTypes } from "src/types/json";
import { ISelectOption } from "src/components/Forms/Select/types";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { required } from "src/components/Forms/antValidators";
import { signUpStore, SignUpStore } from "./RegistrationPagesStore";
import styles from "./RegistrationPages.module.less";
import commonStyles from "../common.module.less";
import {
  CompanyDataModel,
  CompanyTypeVariatoins,
} from "./classes/CompanyDataModel";

interface PropsRegistrationBasePage {
  store: AppStore;
}

type IFormDefaultValues = {
  userCaptcha: string;
} & CompanyTypeVariatoins;

const rec2Options = (dict: Record<string, string>): ISelectOption<string>[] =>
  Object.entries(dict).map((item) => ({ value: item[0], label: item[1] }));

const countryOptions: ISelectOption<string>[] = rec2Options(companyCountries);
const companyTypeOptions: ISelectOption<string>[] = rec2Options(companyTypes);

/* eslint-disable react/jsx-props-no-spreading */
export const RegistrationBasePage: React.FC<PropsRegistrationBasePage> =
  observer(({ store }: PropsRegistrationBasePage) => {
    const captchaRef = React.useRef<{ initializeCaptcha: () => void }>(null);
    const [form] = Form.useForm<IFormDefaultValues>();
    const countryWatched = Form.useWatch("country", form) || defCC;
    const innWatched = Form.useWatch("inn", form);

    const resetCaptcha = () => captchaRef.current?.initializeCaptcha();

    React.useEffect(() => {
      signUpStore.init(countryWatched);
    }, []);
    React.useEffect(() => {
      signUpStore.init(countryWatched);
    }, [countryWatched]);

    React.useEffect(() => {
      if (countryWatched && innWatched) {
        signUpStore.setCompanyModel(countryWatched, innWatched);
        form.validateFields();
      }
    }, [countryWatched, innWatched]);

    const validateInn = async (
      inn: string,
      country: CountryCode
    ): Promise<boolean> => {
      const responses = await SignUpStore.checkCompanyExist(country, {
        inn,
      });
      const fieldsWithErrors: FieldData[] = [];
      responses.forEach((item) => {
        // Если компания нашлась
        if (item.data.status === 200) {
          fieldsWithErrors.push({
            name: item.field,
            errors: [item.message],
          });
          resetCaptcha();
        }
      });

      if (fieldsWithErrors.length > 0) {
        form.setFields(fieldsWithErrors);
      }
      return fieldsWithErrors.length === 0;
    };

    const submitHandler = (formData: IFormDefaultValues) => {
      const { inn, country } = formData;
      signUpStore.setLoading(true);
      validateInn(inn, country!).then((valid) => {
        if (valid) {
          signUpStore.setCompanyModel(country!, inn);
          signUpStore.setCompanyInfo(formData);
          store.setOverlayType("registrationCompanyDetailInfo");
        }
        signUpStore.setLoading(false);
      });
    };

    const fromItems: FormItemDef[] = [
      formItemStd(
        "country",
        t("Country"),
        Select,
        {
          options: countryOptions,
          placeholder: t("Select a country"),
        },
        {
          rules: [required()],
        }
      ),
      formItemStd(
        "inn",
        CompanyDataModel.getCompanyIdMeta(countryWatched)?.label,
        Input,
        {
          placeholder:
            CompanyDataModel.getCompanyIdMeta(countryWatched)?.placeholder,
        },
        {
          rules: [
            required(),
            ...(CompanyDataModel.getCompanyIdMeta(countryWatched)?.rules || []),
          ],
        }
      ),
      formItemStd(
        "type",
        t("Type of company"),
        Select,
        {
          options: companyTypeOptions,
          placeholder: t("Placeholder.Select the type of company"),
        },
        {
          rules: [required()],
        }
      ),
      captchaFormItem("userCaptcha", t("Captcha"), captchaRef),
    ];

    const formHeading =
      t("Registration in S", {
        name: getGlobalParams()?.title || "",
      }) || "";

    return (
      <div className={styles.registrationFormLayout}>
        {signUpStore.loading && (
          <Spin className={commonStyles.spinnerWrapper} size="large" />
        )}
        <Modal className={styles.registrationForm}>
          <FormStd
            heading={formHeading}
            name="baseInfo"
            autoComplete="off"
            onFinishFailed={resetCaptcha}
            submit={submitHandler}
            cancel={() => {
              store.setOverlayType("login");
            }}
            formItems={fromItems}
            form={form}
            submitIcon={<ArrowRightOutlined />}
            cancelIcon={<ArrowLeftOutlined />}
          />
        </Modal>
      </div>
    );
  });
