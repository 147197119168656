/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ArrowDownStdSvg: React.FC<Partial<CustomIconComponentProps>> = (
  props
) => (
  <svg viewBox="0 0 12 8" fill="currentColor" {...props} width="12" height="8">
    <path d="M12 2.0095L6 7.99998L0 2.0095L1.41176 0.599976L6 5.18093L10.5882 0.599976L12 2.0095Z" />
  </svg>
);

export const ArrowDownStd: React.FC<Partial<CustomIconComponentProps>> = (
  props
) => <Icon component={ArrowDownStdSvg} {...props} />;
