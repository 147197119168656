// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-MicroApp-MicroApp-module__microApp--vWmIG {\n  height: 100%;\n  overflow: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/MicroApp/MicroApp.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;AACF","sourcesContent":[".microApp {\n  height: 100%;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"microApp": "src-components-MicroApp-MicroApp-module__microApp--vWmIG"
};
export default ___CSS_LOADER_EXPORT___;
