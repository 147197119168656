import { Spin } from "antd";
import React from "react";
import { classNames } from "src/common/classNames";
import styles from "./styles.module.less";

type PropsSpinOverlayCentred = {
  overlay?: boolean;
};

export const SpinOverlayCentred: React.FC<PropsSpinOverlayCentred> = ({
  overlay,
}) => (
  <div
    className={classNames([styles.spinContainer, [overlay, styles.overlay]])}
  >
    <Spin size="large" />
  </div>
);

SpinOverlayCentred.defaultProps = { overlay: false };
