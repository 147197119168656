export default {
  translation: {
    "Actual address": "Фактический адрес",
    Administration: "Администрирование",
    Addresses: "Адреса",
    Back: "Назад",
    BIN: "БИН",
    Captcha: "Капча",
    "Commercial Director (Full name)": "Коммерческий директор (Ф.И.О.)",
    "Company name": "Наименование компании",
    "Company website": "Сайт компании",
    "Congratulations S": "Поздравляем! Вы зарегистрированы в {{name}}",
    Continue: "Продолжить",
    Country: "Страна",
    Email: "Электронная почта",
    "Full name of the company": "Полное наименование компании",
    "General Director (Full name)": "Генеральный директор (Ф.И.О.)",
    INN: "ИНН",
    Importer: "Импортёр",
    "Instructions new password":
      "Инструкции с новым паролем отправлены на указанный email адрес",
    Kazakhstan: "Казахстан",
    KPP: "КПП",
    "Legal address": "Юридический адрес",
    "Legal form": "Правовая форма",
    Logout: "Выйти",
    Manufacturer: "Производитель",
    "My profile": "Мой профиль",
    "N more items": "ещё {{count}} позиций",
    "N more items_one": "ещё {{count}} позиция",
    "N more items_few": "ещё {{count}} позиции",
    Name: "Имя",
    OGRN: "ОГРН",
    OGRNIP: "ОГРНИП",
    Password: "Пароль",
    "Password changed successfully": "Пароль успешно изменен",
    "Password recovery": "Восстановление пароля",
    Phone: "Телефон",
    "Phone number of the General Director": "Телефон генерального директора",
    "Phone number of the commercial director":
      "Телефон коммерческого директора",
    "Postal address": "Почтовый адрес",
    Register: "Зарегистрироваться",
    "Registration in S": "Регистрация в {{name}}",
    "Repeat the password": "Повторите пароль",
    "Restore password": "Восстановить пароль",
    "Request password": "Запросить пароль",
    "Russian Federation": "Российская Федерация",
    SignIn: "Войти",
    "Short name": "Краткое наименование",
    "Select a country": "Выберите страну",
    "Server error": "Ошибка сервера",
    Supplier: "Поставщик",
    Surname: "Фамилия",
    "Taxation system": "Система налогообложения",
    "Trading network": "Торговая сеть",
    "Type of company": "Тип компании",
    "Update the code": "Обновить код",
    User: "Пользователь",
    UserAgreementMessage:
      "Я ознакомлен с  <пользовательским соглашением>, политикой конфиденциальности и согласен на обработку персональных данных",
    "User Information": "Сведения о пользователе",
    "Year of the company's foundation": "Год основания компании",
    Background: {
      CommOffer: "Согласование коммерческих предложений",
      Description:
        "Автоматизация процессов взаимодействия производителей, дистрибьютеров и поставщиков с торговыми сетями",
      Header: "Автоматизация процессов",
      Promo: "Календарь промо",
      Revaluation: "Заявки на переоценку",
    },
    GlobalSearchTypes: {
      ALL: "Все",
      PRODUCTS: "Товары",
      REQUESTS: "Заявки",
    },
    GlobalSearchRequest: {
      agreement: "Заявка на договорные и коммерческие условия №{{id}}",
      commercial_proposal: "Заявка на коммерческое предложение №{{id}}",
      delivery_schedule: "График поставки товара №{{id}}",
      new_product: "Заявка на ввод и обновление товаров №{{id}}",
      promo: "Заявка на промо №{{id}}",
      other: "Заявка №{{id}}",
    },

    Placeholder: {
      "Full name": "Ф.И.О.",
      "Enter a captcha": "Введите капчу",
      "Enter email": "Введите email",
      "Enter a name": "Введите имя",
      "Enter a new password": "Введите новый пароль",
      "Enter your email address": "Введите электронную почту",
      "Enter your last name": "Введите фамилию",
      "Enter the BIN": "Введите БИН",
      "Enter the INN": "Введите ИНН",
      "Select the type of company": "Выберите тип компании",
    },
    Registration: {
      clickLogin:
        "Для входа в сервис нажмите кнопку “Войти” и введите данные вашей учетной записи",
      companyCode: "Код компании:",
      createdAccontAdmin: "Мы создали личный кабинет администратора компании",
      Password: "Пароль:",
      SpecifiedRegistration: "Указали при регистрации",
      Support:
        "Остались вопроcы? Связаться со службой технической поддержки можно через <портал поддержки пользователей>",
      Username: "Имя пользователя:",
    },
    RegValidationINN: {
      LIQUIDATING: `Регистрация невозможна. Организация с ИНН {{inn}} находится в стадии ликвидации, пожалуйста, обратитесь в техническую поддержку {{email}}`,
      LIQUIDATED: `Регистрация невозможна. Организация с ИНН {{inn}} ликвидирована, пожалуйста, обратитесь в техническую поддержку {{email}}`,
      BANKRUPT: `Регистрация невозможна. Организация с ИНН {{inn}} является банкротом, пожалуйста, обратитесь в техническую поддержку {{email}}`,
      REORGANIZING: `Регистрация невозможна. Организация с ИНН {{inn}} находится в стадии реорганизации, пожалуйста, обратитесь в техническую поддержку {{email}}`,
      DEFAULT: `Организация не найдена в ЕГРЮЛ по ИНН, пожалуйста, обратитесь в техническую поддержку {{email}}`,
    },
    Validation: {
      "Company with this S already exists":
        "Компания с таким {{field}} уже существует",
      "Error creating user": "Ошибка при создании пользователя",
      "Error loading information from dadata":
        "Ошибка при загрузке информации из dadata",
      "Error when registering a company": "Ошибка при регистрации компании",
      "Incorrect code from the picture": "Неправильный код с картинки",
      "Invalid email format": "Неверный формат email",
      "It is necessary to accept the agreement":
        "Необходимо принять соглашение",
      "Only numbers": "Только цифры",
      "The captcha must contain at least 4 characters":
        "Капча должена содержать не менее 4 символов",
      "The field is mandatory": "Поле является обязательным",
      "The INN must contain 10 or 12 characters":
        "ИНН должен содержать 10 или 12 символов",
      "The password must contain at least 4 characters":
        "Пароль должен содержать не менее 4 символов",
      "The user with this email is already registered":
        "Пользователь с таким email уже зарегистрирован",
      "There must be N digits in the S": "Должно быть {{num}} цифр в {{field}}",
      "Passwords don't match": "Пароли не совпадают",
      "User is not found": "Пользователь не найден",
    },
  },
};
