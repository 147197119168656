import * as React from "react";
import { MaskedInput } from "antd-mask-input";
import { MaskedInputProps } from "antd-mask-input/build/main/lib/MaskedInput";
import { PhoneOutlined } from "@ant-design/icons";

/* eslint react/jsx-props-no-spreading: "off" */

interface OnChangeEvent extends React.ChangeEvent<HTMLInputElement> {
  maskedValue: string;
  unmaskedValue: string;
}

interface PropsPhoneInput extends Omit<MaskedInputProps, "onChange" | "mask"> {
  onChange?(newValue: string): void;
}

export const PhoneInput: React.FC<PropsPhoneInput> = (
  props: PropsPhoneInput
) => {
  const { onChange, ...rest } = props;
  // Шаблон номера указан в SRMDEV-2063, хотя возможно с ним будут проблемы
  return (
    <MaskedInput
      size="large"
      addonAfter={<PhoneOutlined />}
      allowClear
      {...rest}
      mask="+7(000)000-00-00"
      onChange={(e: OnChangeEvent) => {
        onChange?.(e.unmaskedValue);
      }}
    />
  );
};

PhoneInput.defaultProps = {
  onChange: undefined,
};
