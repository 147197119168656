/* eslint-disable react/jsx-props-no-spreading */
import { Captcha } from "@nabidam/react-captcha";
import { Form, Input } from "antd";
import { t } from "i18next";
import React from "react";
import { Rule } from "antd/es/form";
import { FormItemDef } from "../FormStd";
import style from "./CaptchaFromItem.module.less";
import { minLength, required } from "../../antValidators";

export type CaptchaRefType = React.RefObject<{
  initializeCaptcha: () => void;
}>;

export const captchaValidator = (userInput: string): Rule => ({
  validator: (_, value: string) => {
    if (value !== userInput) {
      return Promise.reject(
        new Error(t("Validation.Incorrect code from the picture") || "")
      );
    }
    return Promise.resolve();
  },
});

type PropsCaptchaFormItem = {
  name: string;
  label: string;
  captchaRef: CaptchaRefType;
};

export const CaptchaFormItem: React.FC<PropsCaptchaFormItem> = ({
  name,
  label,
  captchaRef,
}: PropsCaptchaFormItem) => {
  const [captcha, setCaptcha] = React.useState("");

  return (
    <>
      {label && (
        <div className={style.label}>
          <label htmlFor={name}>{label}</label>
        </div>
      )}

      <div className={style.container}>
        <Captcha
          setWord={setCaptcha}
          ref={captchaRef}
          inputEl={undefined}
          reloadText={t("Update the code") || ""}
          backgroundColor="#c9deff"
          fontColor="#000"
        />
        <Form.Item
          name={name}
          rules={[required(), minLength(4), captchaValidator(captcha)]}
        >
          <Input placeholder={t("Placeholder.Enter a captcha") || ""} />
        </Form.Item>
      </div>
    </>
  );
};

export const captchaFormItem = (
  name: string,
  label: string,
  captchaRef: CaptchaRefType
): FormItemDef => ({
  render: () => (
    <CaptchaFormItem
      key={name}
      captchaRef={captchaRef}
      name={name}
      label={label}
    />
  ),
});
