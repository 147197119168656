import { FormItemProps, Input, InputProps } from "antd";
import { formItemStd } from "./formItemStd";
import { emailValidator } from "../../antValidators";

export const formItemEmail = (
  name: string,
  label: string,
  compProps: InputProps,
  itemProps: FormItemProps
) =>
  formItemStd(name, label, Input, compProps, {
    ...itemProps,
    rules: [...(itemProps.rules || []), emailValidator()],
    normalize: (value) => value.trim(),
  });
