import { makeAutoObservable } from "mobx";
import { notification } from "antd";
import { debounce, DebounceCounter } from "src/common/debounce";
import { SearchListItem, zSearchResult } from "./GlobalSearch.types";
import { makeSearchList } from "./makeSearchList";

const pageSize = 10;
const loadCounter: DebounceCounter = {};

export const globalSearchStore = makeAutoObservable({
  isActive: false,
  setActive(newValue: boolean) {
    this.isActive = newValue;
  },

  // fullMode=true для специальной страницы глобального поиска
  fullMode: false,
  setFullMode(newValue: boolean) {
    this.fullMode = newValue;
  },
  get isExpanded(): boolean {
    return this.isActive && !this.fullMode;
  },

  text: "",
  setText(newText: string) {
    this.text = newText;
  },
  updateText(newText: string) {
    this.setText(newText);
    this.setActive(true);
    this.smartLoad();
  },

  type: "ALL",
  setType(newType: string) {
    this.type = newType;
  },
  isActiveType(itemType: string): boolean {
    return itemType === this.type;
  },
  setActiveType(newType: string) {
    this.setType(newType);
    this.smartLoad();
  },

  typesList: ["ALL", "PRODUCTS", "REQUESTS"],

  loadCounter: 0,
  setLoadCounter(newValue: number) {
    this.loadCounter = newValue;
  },
  changeLoadCounter(step: -1 | 1) {
    this.setLoadCounter(this.loadCounter + step);
  },
  get loading(): boolean {
    return this.loadCounter !== 0;
  },

  results: [] as SearchListItem[],
  setResults(list: SearchListItem[]) {
    this.results = list;
    this.visibleLength = Math.min(list.length, pageSize);
  },

  visibleLength: 0,
  setVisibleLength(count: number) {
    this.visibleLength = count;
  },
  get isListVisible(): boolean {
    return this.visibleResults.length > 0;
  },

  get visibleResults(): SearchListItem[] {
    return this.results.slice(0, this.visibleLength);
  },
  get nextVisibleIncrement(): number {
    return Math.min(this.results.length - this.visibleLength, pageSize);
  },
  nextPage() {
    this.setVisibleLength(this.visibleLength + this.nextVisibleIncrement);
  },

  smartLoad() {
    if (this.text.length < 3) {
      this.setResults([]);
    } else {
      debounce(loadCounter, 200, () => {
        this.load();
      });
    }
  },

  async load() {
    try {
      this.changeLoadCounter(1);
      // eslint-disable-next-line no-plusplus
      const guard = ++guardBase;
      const resp = await fetch(
        `/search-service/search?query=${encodeURIComponent(this.text)}&type=${
          this.type
        }`
      );
      const raw = await resp.json();
      const result = zSearchResult.parse(raw);
      if (guardBase === guard) {
        // исключить устаревшие запросы
        this.setResults(makeSearchList(result));
      }
    } catch (e) {
      notification.error({ message: e.message });
    } finally {
      this.changeLoadCounter(-1);
    }
  },
});

let guardBase = 0;
