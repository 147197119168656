import * as React from "react";
import { t } from "i18next";
import { appStore } from "src/appStore";
import { observer } from "mobx-react-lite";
import { urlSecure } from "src/navigation/urlSecure";
import { AppRef } from "src/components/AppRef";
import { ArrowDownStd } from "src/icons/ArrowDownStd";
import { AvatarStd } from "src/icons/AvatarStd";
import styles from "./UserMenu.module.less";

interface MenuItem {
  key: string;
  label: React.ReactNode;
  onClick?(): void;
  href?: string;
  condition?(): boolean;
}

const options: MenuItem[] = [
  {
    key: "profile",
    label: <AppRef to={urlSecure("profile")}>{t("My profile")}</AppRef>,
    condition: () => appStore.isAllowed("SRM_PROFILE_EDITPROFILE"),
  },
  {
    key: "admin",
    label: <AppRef to={urlSecure()}>{t("Administration")}</AppRef>,
    condition: () => appStore.isAllowed("SRM_ADM_EDITCOMPANY"),
  },
  {
    key: "logout",
    label: (
      <a
        href="#logout"
        onClick={(e) => {
          e.preventDefault();
          appStore.logout();
        }}
      >
        {t("Logout")}
      </a>
    ),
  },
];

export const UserMenu: React.FC = observer(() => {
  const [open, setOpen] = React.useState(false);
  const onUserClick = () => setOpen(!open);
  const onKey = React.useCallback((e: KeyboardEvent) => {
    if (e.code === "Escape") setOpen(false);
  }, []);
  const stopMouse = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);
  const onMouseDown = React.useCallback(() => {
    setOpen(false);
  }, []);
  React.useEffect(() => {
    window.addEventListener("keyup", onKey);
    window.addEventListener("mousedown", onMouseDown);
    return () => {
      window.removeEventListener("keyup", onKey);
      window.removeEventListener("mousedown", onMouseDown);
    };
  }, []);
  const validOptions = options.filter(({ condition }) => condition?.() ?? true);
  const user = appStore.userInfo.data;
  if (!user) return <div />;
  let name = `${user?.firstName || ""} ${user?.secondName || ""}`;
  if (name === " ") {
    name = (user?.username || "").split("@")[0]!;
  }
  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
  return (
    <div
      className={styles.userMenu}
      onClick={onUserClick}
      onMouseDown={stopMouse}
    >
      <AvatarStd className={styles.avatar} />
      <div className={styles.menuContainer}>
        <span className={styles.userName}>{name}</span>
        <ArrowDownStd
          className={`${styles.arrow} ${open ? styles.open : ""}`}
        />
        {open && (
          <menu
            className={styles.menu}
            onClick={() => setOpen(false)}
            onMouseDown={stopMouse}
          >
            {validOptions.map(({ key, label }) => (
              <div key={key} className={styles.menuItem}>
                {label}
              </div>
            ))}
          </menu>
        )}
      </div>
    </div>
  );
});
