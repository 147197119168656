import * as React from "react";
import { Badge, Tooltip } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { makeUrl } from "src/navigation/makeUrl";
import { observer } from "mobx-react-lite";
import { notificationsStore } from "src/common/notificationsStore";
import { AppRef } from "src/components/AppRef";
import { BellOutlinedStd } from "src/icons/BellOutlinedStd";
import { classNames } from "src/common/classNames";
import { BellFilledStd } from "src/icons/BellFilledStd";
import styles from "./Bell.module.less";

const notificationPageUrl = "/srm/notifications";

type PropsBell = {
  active: Boolean;
};

export const Bell: React.FC<PropsBell> = observer(({ active }) => {
  const makeNotificationUrl = () =>
    active ? -1 : makeUrl(notificationPageUrl);

  const unreadCount = notificationsStore.totalUnreadCount;
  const ActualBell = active ? BellFilledStd : BellOutlinedStd;

  return (
    <div className={classNames([styles.bell, [active, styles.bellActive]])}>
      <AppRef to={makeNotificationUrl}>
        {notificationsStore.status === "connecting" && (
          <Tooltip title="Соединение с центром уведомлений">
            <SyncOutlined
              spin
              className={classNames([styles.icon, styles.spin])}
            />
          </Tooltip>
        )}
        {notificationsStore.status === "none" && (
          <ActualBell className={[styles.icon, styles.error].join(" ")} />
        )}
        {notificationsStore.status === "ready" && (
          <Badge
            className={classNames([
              styles.badge,
              [unreadCount > 99, styles.badgeSmallText],
            ])}
            count={unreadCount}
          >
            <ActualBell className={styles.icon} />
          </Badge>
        )}
      </AppRef>
    </div>
  );
});
